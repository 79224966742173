import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent, TableEvent, TableHeader } from '../../core/table/table.component';
import { finalize, switchMap } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { KeyValuePair, PaginatedResponse } from '@next/next-angular-kit';
import {
  AdminPropertiesService,
  PropertiesService,
  PropertyListFilter,
  PropertyListItemResponse,
  PropertyListRequest,
  PropertyType
} from '@netserv/kalivah-angular-kit';
import { ToastService } from '../../../services/toast/toast.service';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-property-table',
  standalone: true,
  imports: [CommonModule, TableComponent, TranslateModule, RouterLink, FontAwesomeModule, ReactiveFormsModule],
  templateUrl: './property-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyTableComponent implements OnInit {

  /**
   * Filter the table by user id
   */
  @Input() userIdFilter: string | undefined;

  /**
   * Show/hide property id filter
   */
  @Input() showPropertyIdFilter = false;

  /**
   * The properties
   */
  protected paginatedProperties?: PaginatedResponse<PropertyListItemResponse>;

  /**
   * Loading status
   */
  protected isLoading?: boolean;
  protected isLoadingEnabled?: boolean;

  protected tableHeader: TableHeader = [
    { title: 'properties.name', sortable: { column: 'Name', direction: 'desc' } },
    { title: 'properties.type' },
    { title: 'properties.location' },
    { title: 'properties.entered-at', sortable: { column: 'Date' } },
    { title: 'properties.cadastral-info' },
    { title: 'general.enable' },
    { title: '' }
  ];

  protected searchFilters: Array<KeyValuePair> = [
    { key: PropertyListFilter.Name, value: 'properties.name' },
    { key: PropertyListFilter.Location, value: 'properties.location' }
  ];

  protected types: Array<PropertyType> = [];
  protected customFilterForm: FormGroup;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly propertiesService: PropertiesService,
    private readonly adminPropertiesService: AdminPropertiesService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.customFilterForm = this.formBuilder.group({
      type: [null]
    });
  }

  ngOnInit() {
    this.propertiesService.getTypes().subscribe(types => this.types = types);
    if (this.showPropertyIdFilter) {
      this.searchFilters.push({ key: PropertyListFilter.PropertyManager, value: 'properties.property-manager' });
    }
  }

  /**
   * Retrieve the properties
   */
  getProperties(tableEvent: TableEvent): void {
    this.isLoading = true;

    const filter: PropertyListRequest = {
      filters: tableEvent.filterKeys as Array<PropertyListFilter>,
      type: tableEvent.customFilterValue?.type,
      userId: this.userIdFilter,
      query: tableEvent.filter,
      page: tableEvent.pageNumber,
      pageSize: tableEvent.pageSize,
      sorter: tableEvent.sort?.column,
      descending: tableEvent.sort?.direction === 'desc'
    };

    this.adminPropertiesService.list(filter).pipe(
      finalize(() => {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      })
    ).subscribe(res => {
      this.paginatedProperties = res;
    });
  }

  /**
   * Enable disable an user
   * @param event
   * @param property
   */
  toggleEnableProperty(event: Event, property: PropertyListItemResponse): void {
    event.preventDefault();
    const enabled = !!(event.target as HTMLInputElement)?.checked;
    this.isLoadingEnabled = true;
    this.adminPropertiesService.enable(property.id, enabled).pipe(
      switchMap(() => this.translateService.get(enabled ? 'properties.enabled-success' : 'properties.disabled-success')),
      finalize(() => {
        this.isLoadingEnabled = false;
        this.changeDetectorRef.detectChanges();
      })
    ).subscribe(label => {
      property.enabled = enabled;
      this.toastService.success(label);
    });
  }
}
