<div class="card">
  <div class="card-body">

    <h5 class="card-title mb-3">
      {{'users.title' | translate}}
      <a routerLink="/management/users/new" class="btn ms-2">
        <fa-icon icon="circle-plus"></fa-icon>
      </a>
    </h5>

    <app-table [header]="tableHeader"
               [totalCount]="paginatedUsers?.totalCount"
               [totalPages]="paginatedUsers?.totalPages"
               [isLoading]="isLoading"
               [customFilterForm]="customFilterForm"
               [searchFilters]="searchFilters"
               (tableEvent)="getUsers($event)">

      <ng-container customFilters *ngTemplateOutlet="customFilters"></ng-container>

      <tr *ngFor="let user of paginatedUsers?.data">
        <td>{{user.lastName}}</td>
        <td>{{user.firstName}}</td>
        <td>{{user.localizedRole}}</td>
        <td>{{user.registrationDate | date}}</td>
        <td>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch"
                   (change)="toggleEnableUser($event, user)"
                   [disabled]="isLoadingEnabled"
                   [checked]="user.enabled" />
          </div>
        </td>
        <td>
          <a routerLink="/management/users/{{user.id}}">{{'next.general.details' | translate}}</a>
        </td>
      </tr>

      <ng-container emptyText>{{'users.empty-table' | translate}}</ng-container>
    </app-table>

    <ng-template #customFilters [formGroup]="customFilterForm">
      <app-date-range-picker
        [placeholder]="'users.registration-date'|translate"
        formControlName="registrationDate"></app-date-range-picker>

      <select class="form-select w-50" aria-label="Role filter" formControlName="role">
        <option [value]="null" selected>{{'users.role' | translate}}</option>
        <option *ngFor="let role of roles" [value]="role.name">{{role.localizedName}}</option>
      </select>
    </ng-template>
  </div>
</div>
