import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'th[sortable]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()',
  },
})
export class SortableHeaderDirective {
  @Input() sortable!: string;
  @Input() direction?: 'asc' | 'desc';
  @Output() sort = new EventEmitter<{
    column: string;
    direction?: 'asc' | 'desc';
  }>();

  rotate() {
    this.direction = this.direction === 'asc' ? 'desc' : 'asc';
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }

}
