import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NextInputComponent, NextLoadingButtonComponent, NextSpinnerComponent } from '@next/next-angular-kit';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { KalivahFormComponent } from '../../../abstracts/kalivah-form-component';
import {
  AdminPropertiesService,
  GetDocumentResponse,
  UpdatePropertyCadastralResponse
} from '@netserv/kalivah-angular-kit';
import { catchError, finalize, forkJoin, Observable, of } from 'rxjs';
import { FileUploaderComponent } from '../../core/file-uploader/file-uploader.component';
import { HttpEvent } from '@angular/common/http';

@Component({
  standalone: true,
  selector: 'app-property-cadastral-form[propertyId]',
  templateUrl: './property-cadastral-form.component.html',
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, NextInputComponent, NextLoadingButtonComponent, FontAwesomeModule, NextSpinnerComponent, FileUploaderComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyCadastralFormComponent extends KalivahFormComponent<UpdatePropertyCadastralResponse> implements OnInit {

  @Input() propertyId!: number;

  isLoadingData = false;
  cadastralDocument?: GetDocumentResponse;

  constructor(
    private readonly adminPropertiesService: AdminPropertiesService
  ) {
    super();
  }

  protected initForm(): FormGroup {
    return this.formBuilder.group({
      cir: [null, [Validators.minLength(14), Validators.maxLength(14)]],
      sheet: [null],
      parcel: [null]
    });
  }

  ngOnInit() {
    this.isLoadingData = true;
    forkJoin([
      this.adminPropertiesService.getCadastrals(this.propertyId),
      this.adminPropertiesService.getCadastralDocument(this.propertyId).pipe(
        catchError(() => of(undefined))
      )
    ]).pipe(
      finalize(() => {
        this.isLoadingData = false;
        this.changeDetectorRef.detectChanges();
      })
    ).subscribe(([cadastral, document]) => {
      this.formGroup.patchValue(cadastral);
      this.cadastralDocument = document;
    });
  }

  protected submitAction(formValue: any): Observable<UpdatePropertyCadastralResponse> {
    return this.adminPropertiesService.updateCadastrals(this.propertyId, formValue);
  }

  protected submitComplete(res: UpdatePropertyCadastralResponse): void {
    this.translateService.get('properties.update-success').subscribe(label => {
      this.toastService.success(label);
    });
  }

  protected upload$ = (fileList: FileList): Array<Observable<HttpEvent<GetDocumentResponse>>> => {
    return Array.from(fileList).map(file => this.adminPropertiesService.createCadastralDocument(this.propertyId, file));
  };

  protected onFileUpload(document: GetDocumentResponse): void {
    this.cadastralDocument = document;
  }

}
