import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Optional,
  Renderer2,
  Self,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbTypeahead, NgbTypeaheadModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccommodationService, AccommodationsService } from '@netserv/kalivah-angular-kit';
import { debounceTime, distinctUntilChanged, filter, map, merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { AbstractFormComponent } from '@next/next-angular-kit';
import { NgControl } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-accommodation-service-search',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbTypeaheadModule, FontAwesomeModule],
  templateUrl: './accommodation-service-search.component.html',
  styleUrls: ['./accommodation-service-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AccommodationServiceSearchComponent extends AbstractFormComponent<Array<AccommodationService>> implements OnInit {

  protected services: Array<AccommodationService> = [];

  @ViewChild('instance', { static: true }) protected instance!: NgbTypeahead;
  protected focus$ = new Subject<string>();
  protected click$ = new Subject<string>();

  constructor(
    protected override readonly _renderer: Renderer2,
    protected override readonly _elementRef: ElementRef,
    @Self() @Optional() protected override readonly _ngControl: NgControl,
    protected override readonly _translateService: TranslateService,
    protected override readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly accommodationsService: AccommodationsService
  ) {
    super(_renderer, _elementRef, _ngControl, _translateService, _changeDetectorRef);
  }

  get selectedServices(): Array<AccommodationService> {
    return this.control.value || [];
  }

  override ngOnInit() {
    super.ngOnInit();
    this.accommodationsService.getServices().subscribe(services => {
      this.services = services;
    });
  }


  searchServices: OperatorFunction<string, readonly AccommodationService[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => {
          let services = this.services.filter(s => !this.selectedServices.some(ss => ss.id === s.id));
          if (term !== '') {
            services = services.filter(v => v.name.toLowerCase().includes(term.toLowerCase()));
          }
          return services;
        }
      )
    );
  };

  searchServicesFormatter = (result: AccommodationService) => result.name;

  onSelectService(event: NgbTypeaheadSelectItemEvent): void {
    event.preventDefault();
    this.control.setValue([...this.selectedServices, event.item]);
  }

  removeService(serviceId: number): void {
    this.control.setValue(this.selectedServices.filter(s => s.id !== serviceId));
  }
}
