import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  nextAuthActions,
  NextCheckboxComponent,
  NextInputComponent,
  NextLoadingButtonComponent,
  NextValidators
} from '@next/next-angular-kit';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  AdminAccountService,
  AdminUsersService,
  CreateUserRequest,
  CreateUserResponse,
  GeoInfo,
  GetUserResponse,
  UpdateUserRequest,
  UserRoleType
} from '@netserv/kalivah-angular-kit';
import { KalivahFormComponent } from '../../../abstracts/kalivah-form-component';
import { EMPTY, first, forkJoin, merge, Observable, switchMap, tap } from 'rxjs';
import { Router } from '@angular/router';
import { CountrySelectComponent } from '../../core/country-select/country-select.component';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerComponent } from '../../core/datepicker/datepicker.component';
import { Store } from '@ngrx/store';
import { GeoInfoAutocompleteComponent } from '../../core/geo-info-autocomplete/geo-info-autocomplete.component';

@Component({
  selector: 'app-user-info-form',
  standalone: true,
  imports: [
    CommonModule, FontAwesomeModule, NextInputComponent, NextLoadingButtonComponent, ReactiveFormsModule,
    TranslateModule, CountrySelectComponent, NgbCollapse, NextCheckboxComponent, DatepickerComponent, GeoInfoAutocompleteComponent
  ],
  templateUrl: './user-info-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserInfoFormComponent extends KalivahFormComponent<CreateUserResponse | void> implements OnInit, OnChanges {

  /**
   * The user info
   */
  @Input() user: GetUserResponse | undefined;

  /**
   * Emit value when firstName and lastName was edited
   */
  @Output() nameChange: EventEmitter<{ firstName: string, lastName: string }>;


  protected maxBirthDate: Date;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly adminUsersService: AdminUsersService,
    private readonly adminAccountService: AdminAccountService
  ) {
    super();
    this.nameChange = new EventEmitter<{ firstName: string; lastName: string }>();

    this.maxBirthDate = new Date();
    this.maxBirthDate.setFullYear(this.maxBirthDate.getFullYear() - 18);
  }

  protected initForm(): FormGroup {
    return this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      geo: [null, Validators.required],
      nationality: [null, Validators.required],
      email: [null, [Validators.required, NextValidators.email]],
      phoneNumber: [null, [Validators.required, NextValidators.tel]]
    });
  }

  ngOnInit() {
    const firstNameControl = this.formGroup.get('firstName')!;
    const lastNameControl = this.formGroup.get('lastName')!;
    merge(firstNameControl.valueChanges, lastNameControl.valueChanges).subscribe(() => {
      this.nameChange.next({
        firstName: firstNameControl.value,
        lastName: lastNameControl.value
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.isAdmin) {
      const pIvaFieldValidator = NextValidators.conditional(Validators.required, () => {
        return !!this.formGroup.get('isPIva')?.value;
      });

      this.formGroup = this.formBuilder.group({
        ...this.formGroup.controls,
        birthDate: [null, Validators.required],
        cf: [null, [Validators.required, NextValidators.taxCode]],
        iban: [null, [Validators.required, NextValidators.iban]],
        accountHolder: [null, Validators.required],

        isPIva: [false],
        businessName: [null, pIvaFieldValidator],
        businessEmail: [null, [NextValidators.email, pIvaFieldValidator]],
        pIva: [null, [NextValidators.vatNumber, pIvaFieldValidator]]
      });

      this.formGroup.get('isPIva')?.valueChanges.subscribe(value => {
        const controlNames = ['businessName', 'businessEmail', 'pIva'];
        controlNames.forEach(controlName => {
          const control = this.formGroup.get(controlName);
          control?.updateValueAndValidity({ emitEvent: false });
          if (!value && control) {
            control.setValue(null, { emitEvent: false });
          }
        });
      });
    }

    if (this.user) {
      this.formGroup.patchValue({
        ...this.user,
        geo: <GeoInfo>{ address: this.user.address },
        nationality: this.user.countryCode
      });
    }
  }

  /**
   * Check the user is admin
   */
  get isAdmin(): boolean {
    return this.user?.role === UserRoleType.Administrator;
  }

  protected submitAction(formValue: any): Observable<CreateUserResponse | void> {
    const { geo, ...value } = formValue;
    const formUser: CreateUserRequest = {
      ...value,
      address: (<GeoInfo>geo).address
    };

    return forkJoin([
      this.adminUsersService.currentUser$.pipe(first()),
      this.adminUsersService.userHasRole(UserRoleType.Administrator).pipe(first())
    ]).pipe(
      switchMap(([currentUser, currentIsAdmin]) => {
        if (!this.user) {
          if (!currentIsAdmin) {
            this.router.navigate(['forbidden']).then();
            return EMPTY;
          }
          // Administrator create new user
          return this.adminUsersService.create(formUser);
        }

        if (currentUser?.id === this.user.id) {
          // Self profile update
          return this.adminAccountService.update(formUser as UpdateUserRequest).pipe(
            tap(() => {
              this.store.dispatch(nextAuthActions.setUserManually({
                user: {
                  ...currentUser,
                  firstName: formUser.firstName, lastName: formUser.lastName,
                  email: formUser.email, birthDate: formUser.birthDate,
                  phoneNumber: formUser.birthDate, cf: formUser.cf
                }
              }));
            })
          );
        }

        if (!currentIsAdmin) {
          this.router.navigate(['forbidden']).then();
          return EMPTY;
        }
        // Administrator update user
        return this.adminUsersService.update(this.user.id, formUser as UpdateUserRequest);
      })
    );
  }

  protected submitComplete(res: CreateUserResponse | void): void {
    if (!res?.id) {
      this.translateService.get('users.update-success').subscribe(label => {
        this.toastService.success(label);
      });
      return;
    }

    this.translateService.get('users.creation-success').subscribe(label => {
      this.router.navigate([`/management/users/`, res.id]).then(() => {
        this.toastService.success(label);
      });
    });
  }
}
