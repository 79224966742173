<div class="text-center">
  <img [attr.src]="userProfileImage" class="rounded-circle img-thumbnail avatar-image" alt="user-photo" />
  <h4 class="mb-0 mt-2">{{dynamicFirstName || user?.firstName}} {{dynamicLastName || user?.lastName}}</h4>
  <small class="mb-3 text-muted">
    {{this.user?.localizedRole ?? ('properties.property-manager' | translate)}}
  </small>
</div>

<div class="container-fluid pt-4">
  <dl class="row justify-content-center mt-4">
    <ng-container>
      <dt class="col-sm-4 text-truncate">{{'general.enabled' | translate}}</dt>
      <dd class="col-sm-7">{{(user?.enabled ? 'general.yes' : 'general.no') | translate}}</dd>
    </ng-container>
    <ng-container>
      <dt class="col-sm-4 text-truncate">{{'users.registered-at' | translate}}</dt>
      <dd class="col-sm-7">{{user?.registrationDate ? (user?.registrationDate | date) : '-'}}</dd>
    </ng-container>
  </dl>
</div>
