import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  NextCheckboxComponent,
  NextInputComponent,
  NextLoadingButtonComponent,
  NextPasswordInputComponent,
  NextValidators
} from '@next/next-angular-kit';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { KalivahFormComponent } from '../../../abstracts/kalivah-form-component';
import { AdminRegistrationService, RegisterUserResponse } from '@netserv/kalivah-angular-kit';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, NextInputComponent, NextLoadingButtonComponent, NextPasswordInputComponent, NgOptimizedImage, ReactiveFormsModule, RouterLink, TranslateModule, NextCheckboxComponent],
  templateUrl: './sign-up.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignUpComponent extends KalivahFormComponent<RegisterUserResponse> {

  constructor(
    private readonly router: Router,
    private readonly adminRegistrationService: AdminRegistrationService
  ) {
    super();
  }

  protected initForm(): FormGroup {
    return this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, NextValidators.email]],
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required],
      isPIva: [false],
    }, {
      validators: [NextValidators.passwordMatch]
    });
  }

  protected submitAction(formValue: any): Observable<RegisterUserResponse> {
    return this.adminRegistrationService.register(formValue);
  }

  protected submitComplete(res: RegisterUserResponse): void {
    this.translateService.get('auth.success-signup-message').subscribe(label => {
      this.router.navigate(['/login']).then(() => {
        this.toastService.success(label);
      });
    });
  }
}
