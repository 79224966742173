<div class="w-100 text-center mb-4">
  <img ngSrc="/assets/images/password-logo.svg" class="img-fluid" alt="kalivah-logo" height="132" width="138">
</div>

<form [formGroup]="formGroup" (ngSubmit)=submit()>

  <div class="mb-3">
    <next-password-input [label]="'auth.new-password' | translate" formControlName="password">
      <fa-icon icon="eye-slash" hideIcon></fa-icon>
      <fa-icon icon="eye" showIcon></fa-icon>
    </next-password-input>
  </div>

  <div class="mb-5">
    <next-password-input [label]="'auth.confirm-password' | translate"
                         [validatePassword]="false"
                         formControlName="confirmPassword">
      <fa-icon icon="eye-slash" hideIcon></fa-icon>
      <fa-icon icon="eye" showIcon></fa-icon>
    </next-password-input>
  </div>

  <div class="text-center">
    <button class="btn btn-rounded btn-primary" type="submit" [isLoading]="isLoading" [disabled]="isLoading">
      {{'auth.change-password' | translate}}
    </button>
  </div>

  <div class="mt-3 text-center">
    <a class="text-primary" routerLink="/login">{{'auth.back-to-login' | translate}}</a>
  </div>
</form>
