import { isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { ToastComponent } from './components/core/toast/toast.component';
import { ErrorInterceptor } from './interceptor/error.interceptor.service';
import { KalivahAngularKitModule } from '@netserv/kalivah-angular-kit';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { appReducer } from './store/reducers/app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './store/effects/app.effects';
import { NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { AppDateParserFormatter, AppDatepickerI18n } from './utils/bs-datepicker';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    KalivahAngularKitModule.forRoot(environment.kalivahInit),
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.defaultLanguage,
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpBackend) => new MultiTranslateHttpLoader(http, [
          './next-angular-kit/i18n/', // Load library translations first, so you can edit the keys in your localization file
          './assets/i18n/' // Your i18n location
        ]),
        deps: [HttpBackend]
      }
    }),
    ToastComponent,
    StoreModule.forFeature('app', appReducer),
    EffectsModule.forFeature([AppEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() })
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    { provide: NgbDatepickerI18n, useClass: AppDatepickerI18n },
    { provide: NgbDateParserFormatter, useClass: AppDateParserFormatter }
  ]
})
export class AppModule {
}
