import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AdminRegistrationService,
  AdminUsersService,
  GetDocumentResponse,
  UserRoleType
} from '@netserv/kalivah-angular-kit';
import { catchError, EMPTY, finalize, first, forkJoin, Observable, of, switchMap, throwError } from 'rxjs';
import { HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { FileUploaderComponent } from '../../core/file-uploader/file-uploader.component';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-documents',
  standalone: true,
  imports: [CommonModule, FileUploaderComponent, TranslateModule, FontAwesomeModule],
  templateUrl: './user-documents.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDocumentsComponent implements OnInit {

  @Input() userId: string | undefined;

  isLoading = false;

  document?: GetDocumentResponse;

  private currentIsAdmin = false;

  constructor(
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly adminUsersService: AdminUsersService,
    private readonly adminRegistrationService: AdminRegistrationService
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    forkJoin([
      this.adminUsersService.currentUser$.pipe(first()),
      this.adminUsersService.userHasRole(UserRoleType.Administrator).pipe(first())
    ]).pipe(
      switchMap(([currentUser, currentIsAdmin]) => {
        if (!currentIsAdmin) {
          return this.adminRegistrationService.getDocument();
        }

        if (currentIsAdmin && this.userId && currentUser?.id !== this.userId) {
          this.currentIsAdmin = true;
          return this.adminRegistrationService.getDocumentByUserId(this.userId);
        }

        this.router.navigate(['forbidden']).then();
        return EMPTY;
      }),
      finalize(() => {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) {
          return of(undefined);
        }
        return throwError(() => err);
      })
    ).subscribe(document => {
      this.document = document;
    });
  }

  protected upload$ = (fileList: FileList): Array<Observable<HttpEvent<GetDocumentResponse>>> => {
    if (this.userId && this.currentIsAdmin) {
      return Array.from(fileList).map(file => this.adminRegistrationService.createDocumentByUserId(this.userId!, file));
    }
    return Array.from(fileList).map(file => this.adminRegistrationService.createDocument(file));
  };

  protected onFileUpload(document: GetDocumentResponse): void {
    this.document = document;
  }
}
