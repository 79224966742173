<label for="user-autocomplete" class="form-label">{{(label || 'users.title') | translate}}</label>
<input #instance="ngbTypeahead"
       id="user-autocomplete"
       type="text"
       class="form-control"
       [formControl]="control"
       [ngbTypeahead]="searchUsers"
       [inputFormatter]="searchUsersFormatter"
       [resultFormatter]="searchUsersFormatter"
       (click)="click$.next($any($event).target.value)"
       [class.is-valid]="isValid"
       [class.is-invalid]="isInvalid || searchFailed"
       [placeholder]="placeholder"
       (blur)="markAsTouched()"
       [editable]="false" />

<div class="invalid-feedback" *ngIf="isInvalid || searchFailed">
  <ng-container *ngIf="!searchFailed; else searchFailedMessage">
    {{invalidMessage | async}}
  </ng-container>
  <ng-template #searchFailedMessage>
    {{'users.empty-table' | translate}}
  </ng-template>
</div>
