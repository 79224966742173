import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { finalize, first, of, switchMap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import {
  PropertyInfoFormComponent
} from '../../../components/properties/property-info-form/property-info-form.component';
import {
  PropertyDescriptionFormComponent
} from '../../../components/properties/property-description-form/property-description-form.component';
import {
  PropertyImageGalleryFormComponent
} from '../../../components/properties/property-image-gallery-form/property-image-gallery-form.component';
import {
  AccommodationTableComponent
} from '../../../components/accommodations/accommodation-table/accommodation-table.component';
import { AdminPropertiesService, GetPropertyResponse } from '@netserv/kalivah-angular-kit';
import { NextSpinnerComponent } from '@next/next-angular-kit';
import {
  PropertyCadastralFormComponent
} from '../../../components/properties/property-cadastral-form/property-cadastral-form.component';

@Component({
  selector: 'app-property-upsert',
  standalone: true,
  imports: [CommonModule, PropertyInfoFormComponent, PropertyDescriptionFormComponent, PropertyImageGalleryFormComponent, NextSpinnerComponent, AccommodationTableComponent, PropertyCadastralFormComponent],
  templateUrl: './property-upsert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyUpsertComponent implements OnInit {

  /**
   * Page loading status
   */
  isLoading?: boolean;

  /**
   * The property
   */
  property?: GetPropertyResponse;

  /**
   * Force creation for userId
   */
  userId?: string;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly adminPropertiesService: AdminPropertiesService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.params.pipe(
      first(),
      switchMap(params => {
        const propertyId = !!params['propertyId'] && Number(params['propertyId']);
        if (propertyId && !isNaN(propertyId) && propertyId !== 0) {
          return this.adminPropertiesService.get(propertyId);
        }

        this.userId = params['userId'];
        return of(undefined);
      }),
      finalize(() => {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      })
    ).subscribe(property => {
      this.property = property;
      if (this.property) {
        this.breadcrumbService.set( // Personalize the breadcrumb for current property
          this.router.url,
          this.property.name
        );
      }
    });
  }

}
