<div class="card">
  <div class="card-body">
    <form [formGroup]="formGroup" (ngSubmit)="submit()">

      <h5 class="card-title mb-3">
        {{'properties.property-description' | translate}}
      </h5>

      <ul ngbNav #nav="ngbNav" [activeId]="0" class="nav-pills flex-column flex-sm-row" formArrayName="descriptions">
        <li *ngFor="let descriptionForm of descriptionsForm; let i = index" [ngbNavItem]="i">
          <a ngbNavLink>{{descriptionForm.get('languageCode')?.value === 'it' ? 'ITA' : 'ENG'}}</a>
          <ng-template ngbNavContent>
            <div class="form-floating" [formGroup]="descriptionForm">
              <textarea class="form-control" style="height: 100px"
                        formControlName="description"></textarea>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-3"></div>

      <div class="text-end mt-3">
        <button type="submit" class="btn btn-sm btn-secondary" [isLoading]="isLoading"
                [disabled]="isLoading || formGroup.pristine">
          <fa-icon *ngIf="!isLoading" icon="paper-plane" class="me-1"></fa-icon>
          {{'next.general.save' | translate}}
        </button>
      </div>
    </form>

  </div>
</div>
