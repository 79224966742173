<div class="w-100 text-center mb-4">
  <img *ngIf="emailSent; else passwordImage" ngSrc="/assets/images/sent-logo.svg" class="img-fluid" alt="kalivah-logo" height="132" width="138">
  <ng-template #passwordImage>
    <img ngSrc="/assets/images/password-logo.svg" class="img-fluid" alt="kalivah-logo" height="132" width="138">
  </ng-template>
</div>

<form [formGroup]="formGroup" (ngSubmit)=submit()>

  <div class="text-center mb-4">
    <strong class="fs-5 mb-4">{{'auth.submit-forgot-text' | translate}}</strong>
    <div *ngIf="!emailSent">{{'auth.text-forgot-form' | translate}}</div>
    <div *ngIf="emailSent" class="fs-5 py-4">{{'auth.success-forgot-message' | translate}}</div>
  </div>

  <ng-container *ngIf="!emailSent">
    <div class="mb-5">
      <next-input id="email" [label]="'auth.email-address' | translate" placeholder="name@example.com"
                  formControlName="email"></next-input>
    </div>

    <div class="text-center">
      <button class="btn btn-rounded btn-primary" type="submit" [isLoading]="isLoading" [disabled]="isLoading">
        {{'auth.submit-forgot-text' | translate}}
      </button>
    </div>
  </ng-container>

  <div class="mt-3 text-center">
    <a class="text-danger" routerLink="/login">{{'auth.back-to-login' | translate}}</a>
  </div>
</form>
