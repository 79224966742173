<div class="w-25">
  <label [for]="id" class="form-label">{{'properties.accommodations.services' | translate}}</label>
  <input #instance="ngbTypeahead"
         [id]="id"
         class="form-control"
         popupClass="search-services-popup"
         [placeholder]="'general.elements-selected' | translate: {elements: selectedServices.length}"
         [ngbTypeahead]="searchServices"
         [inputFormatter]="searchServicesFormatter"
         [resultFormatter]="searchServicesFormatter"
         [resultTemplate]="rt"
         (focus)="focus$.next($any($event).target.value)"
         (click)="click$.next($any($event).target.value)"
         [editable]="false"
         (selectItem)="onSelectService($event)" />
</div>

<div class="d-flex flex-wrap gap-3 mt-3">
      <span *ngFor="let service of selectedServices" class="badge text-bg-secondary text-white">
        <fa-icon icon="xmark" class="me-3 cursor-pointer" size="xl" (click)="removeService(service.id)"></fa-icon>
        {{service.name}}
        <fa-icon *ngIf="service.icon" [icon]="service.icon" class="ms-1" size="lg"></fa-icon>
      </span>
</div>

<ng-template #rt let-r="result" let-t="term">
  <div class="d-flex justify-content-between">
    <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
    <fa-icon *ngIf="r['icon']" [icon]="r['icon']"></fa-icon>
  </div>
</ng-template>
