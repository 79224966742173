import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, TemplateRef } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AdminPropertiesService, Image } from '@netserv/kalivah-angular-kit';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { finalize, forkJoin, Observable, tap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NextLoadingButtonComponent, NextSpinnerComponent } from '@next/next-angular-kit';
import { DeleteModalComponent } from '../../core/delete-modal/delete-modal.component';

@Component({
  selector: 'app-property-image-gallery-form[propertyId][images]',
  standalone: true,
  imports: [CommonModule, TranslateModule, FontAwesomeModule, NgOptimizedImage, NextSpinnerComponent, NextLoadingButtonComponent],
  templateUrl: './property-image-gallery-form.component.html',
  styleUrls: ['./property-image-gallery-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyImageGalleryFormComponent {

  /**
   * The property id
   */
  @Input() propertyId!: number;

  /**
   * The property images
   */
  @Input() images!: Array<Image>;

  isLoading = false;

  constructor(
    private readonly modalService: NgbModal,
    private readonly adminPropertiesService: AdminPropertiesService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
  }

  /**
   * Add the image on property
   * @param target
   */
  addImage(target: EventTarget | null): void {
    const images = (target as HTMLInputElement)?.files;
    if (images) {
      this.isLoading = true;
      const sources: Array<Observable<Image>> = Array.from(images).map(
        (image: File) => this.adminPropertiesService.createImage(this.propertyId, image)
      );
      forkJoin(sources).pipe(
        finalize(() => {
          this.isLoading = false;
          this.changeDetectorRef.detectChanges();
        })
      ).subscribe((res: Array<Image>) => {
        this.images.push(...res);
      });
    }
  }

  /**
   * Open the delete modal
   * @param template
   * @param image
   */
  openDeleteModal(template: TemplateRef<any>, image: Image): void {
    const modalRef = this.modalService.open(DeleteModalComponent, { size: 'lg', scrollable: true });
    modalRef.componentInstance.i18nTitle = '';
    modalRef.componentInstance.bodyTemplate = template;
    modalRef.componentInstance.submitAction = this.adminPropertiesService.deleteImage(this.propertyId, image.id).pipe(
      tap(() => {
        this.images = this.images.filter(im => im.id !== image.id);
        this.changeDetectorRef.detectChanges();
      })
    );
  }
}
