<div class="w-100 text-center mb-3">
  <img ngSrc="/assets/images/vertical-logo.svg" class="img-fluid" alt="kalivah-logo" height="132" width="121">
</div>

<form [formGroup]="formGroup" (ngSubmit)=submit()>

  <div class="mb-3">
    <next-input id="email" [label]="'auth.email-address' | translate" placeholder="email@example.com"
                formControlName="email"></next-input>
  </div>

  <div class="mb-3">
    <next-password-input [label]="'auth.password' | translate" [validatePassword]="false"
                         formControlName="password">
      <fa-icon icon="eye-slash" hideIcon></fa-icon>
      <fa-icon icon="eye" showIcon></fa-icon>
    </next-password-input>
  </div>

  <div class="d-flex justify-content-between mb-3">
    <div class="mb-4">
      <next-checkbox [label]="'auth.remember-me' | translate" formControlName="rememberMe"></next-checkbox>
    </div>

    <a class="text-danger" routerLink="/forgot-password">{{'auth.forgot-password' | translate}}?</a>
  </div>

  <div class="text-center mt-3">
    <button class="btn btn-rounded btn-primary" type="submit" [isLoading]="isLoading" [disabled]="isLoading">
      {{'auth.submit-login-text' | translate}}
    </button>

    <div class="mt-2">
      <a class="text-danger" routerLink="/sign-up">{{'auth.signup-link-text' | translate}}</a>
    </div>
  </div>
</form>

