import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  NextError,
  NextForgotPasswordPage,
  NextInputComponent,
  NextLoadingButtonComponent
} from '@next/next-angular-kit';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule, NgOptimizedImage } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, NextInputComponent, NextLoadingButtonComponent, RouterLink, NgOptimizedImage],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordComponent extends NextForgotPasswordPage {

  emailSent = false;

  protected onSuccess(): void {
    this.emailSent = true;
    this.changeDetectorRef.detectChanges();
  }

  protected override onError(error?: NextError, isInvalidForm?: boolean) {
    if (isInvalidForm) {
      this.formGroup.markAllAsTouched();
    }
  }
}
