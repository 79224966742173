import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ToastInfo, ToastService } from '../../../services/toast/toast.service';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { NgForOf, NgIf } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-toast',
  imports: [NgbToast, NgForOf, NgIf],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent {

  @Input() delay = 5000;
  @Input() autoHide = true;

  protected toasts: Array<ToastInfo> = [];

  constructor(
    private readonly toastService: ToastService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.toastService.onToast().subscribe(toast => {
      this.toasts.push(toast);
      this.changeDetectorRef.detectChanges();
    });
  }

  /**
   * Remove the toast
   * @param toast
   */
  protected remove(toast: ToastInfo): void {
    this.toasts = this.toasts.filter(t => t != toast);
    this.changeDetectorRef.detectChanges();
  }

  protected getToastClass(toast: ToastInfo): string | undefined {
    switch (toast.type) {
      case 'success':
        return 'bg-success text-white';
      case 'error':
        return 'bg-secondary text-white';
      case 'warning':
        return 'bg-warning text-white';
      case 'info':
        return 'bg-info text-white';
      default:
        return undefined;
    }
  }
}
