import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ToastService } from '../services/toast/toast.service';
import { NextError } from '@next/next-angular-kit';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('assets')) {
      return next.handle(request); // Frontend request to assets
    }

    // NOTE: The token is inserted through the @next/next-angular-kit library's interceptor
    return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
      // Catch request error
      switch (err.status) {
        case 401:
          break;
        case 403:
          this.router.navigate(['forbidden'], { replaceUrl: true }).then();
          break;
        case 404:
          if (request.method !== 'GET') {
            this.showErrorToast(err);
            break;
          }
          if (['/document'].some(u => request.url.includes(u))) {
            break;
          }

          this.router.navigate(['not-found'], { replaceUrl: true }).then();
          break;
        default:
          this.showErrorToast(err);
      }

      return throwError(() => err);
    }));
  }

  /**
   * Show the toast error
   * @param err
   * @private
   */
  private showErrorToast(err: HttpErrorResponse): void {
    this.translateService.get('next.errors.generic-support-message').subscribe(label => {
      let nextError: NextError | string | undefined = err.error;
      if (typeof nextError === 'string') {
        nextError = undefined;
      }

      const message = nextError?.uiMessage ?? label;
      this.toastService.error(message);
    });
  }
}
