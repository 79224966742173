<div class="modal-header">
  <h4 class="modal-title">{{(i18nTitle !== undefined ? i18nTitle : 'general.delete-modal-title') | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>


<div class="modal-body">
  {{bodyString}}

  <ng-container *ngIf="bodyTemplate">
    <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
  </ng-container>
</div>

<div class="modal-footer d-flex justify-content-end">
  <button type="button" class="btn btn-sm btn-secondary"
          [isLoading]="isLoading || false"
          [disabled]="isLoading" (click)="submit()">
    <fa-icon *ngIf="!isLoading" icon="trash" class="me-2"></fa-icon>
    {{'general.remove' | translate}}
  </button>
</div>
