import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NextSpinnerComponent } from '@next/next-angular-kit';
import { PropertyTableComponent } from '../../../components/properties/property-table/property-table.component';
import { AdminAccountService, AdminUsersService, GetUserResponse, UserRoleType } from '@netserv/kalivah-angular-kit';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { finalize, first, of, switchMap } from 'rxjs';
import { UserDetailsComponent } from '../../../components/users/user-details/user-details.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ChangePasswordComponent } from '../../../components/users/change-password/change-password.component';
import { UserInfoFormComponent } from '../../../components/users/user-info-form/user-info-form.component';
import { UserDocumentsComponent } from '../../../components/users/user-documents/user-documents.component';

@Component({
  standalone: true,
  selector: 'app-user-upsert',
  templateUrl: './user-upsert.component.html',
  imports: [CommonModule, NextSpinnerComponent, NgbNavModule, TranslateModule, UserDetailsComponent, UserInfoFormComponent, ChangePasswordComponent, PropertyTableComponent, UserDocumentsComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserUpsertComponent implements OnInit {

  /**
   * Page loading status
   */
  protected isLoading?: boolean;

  /**
   * The user
   */
  protected user?: GetUserResponse;

  /**
   * Check is a profile page
   * @protected
   */
  protected isProfilePage = false;

  /**
   * Sync firstname dynamically
   */
  dynamicFirstName: string | undefined;

  /**
   * Sync lastname dynamically
   */
  dynamicLastName: string | undefined;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly adminUsersService: AdminUsersService,
    private readonly adminAccountService: AdminAccountService
  ) {
  }

  /**
   * Check the user is admin
   */
  get isAdmin(): boolean {
    return this.user?.role === UserRoleType.Administrator;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.params.pipe(
      first(),
      switchMap(params => {
        if (this.route.snapshot.data['isProfile']) {
          this.isProfilePage = true;
          return this.adminAccountService.get();
        }
        return params['userId'] ? this.adminUsersService.get(params['userId']) : of(undefined);
      }),
      finalize(() => {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      })
    ).subscribe(user => {
      this.user = user;
      if (this.user) {
        // Personalize the breadcrumb for current user
        this.breadcrumbService.set(this.router.url, `${this.user.firstName} ${this.user.lastName}`.trim());
      }
    });
  }

  onNameChange(event: { firstName: string, lastName: string }): void {
    this.dynamicFirstName = event.firstName;
    this.dynamicLastName = event.lastName;
  }
}
