import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@next/next-angular-kit';
import { CoreRoutingModule } from './pages/core/core-routing.module';
import { ManagementRoutingModule } from './pages/management/management-routing.module';
import { UserRoleType } from '@netserv/kalivah-angular-kit';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => CoreRoutingModule
  },
  {
    path: 'management',
    loadChildren: () => ManagementRoutingModule,
    canActivate: [AuthGuard],
    data: {
      roles: [UserRoleType.Administrator, UserRoleType.PropertyManager]
    }
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
