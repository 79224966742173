<div class="w-100 text-center mb-3">
  <img ngSrc="/assets/images/vertical-logo.svg" class="img-fluid" alt="kalivah-logo" height="132" width="121">
</div>

<form [formGroup]="formGroup" (ngSubmit)=submit()>

  <div class="row">
    <div class="col-sm-12 col-md-6 mb-3">
      <next-input [label]="'auth.email-address' | translate" placeholder="email@example.com"
                  formControlName="email"></next-input>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6 mb-3">
      <next-input [label]="'users.firstname' | translate"
                  formControlName="firstName"
                  placeholder="Mario"></next-input>
    </div>

    <div class="col-sm-12 col-md-6 mb-3">
      <next-input [label]="'users.lastname' | translate"
                  formControlName="lastName"
                  placeholder="Rossi"></next-input>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6 mb-3">
      <next-password-input [label]="'auth.password' | translate"
                           autocomplete="new-password"
                           formControlName="password">
        <fa-icon icon="eye-slash" hideIcon></fa-icon>
        <fa-icon icon="eye" showIcon></fa-icon>
      </next-password-input>
    </div>

    <div class="col-sm-12 col-md-6 mb-3">
      <next-password-input [label]="'auth.confirm-password' | translate"
                           autocomplete="off"
                           [validatePassword]="false"
                           formControlName="confirmPassword">
        <fa-icon icon="eye-slash" hideIcon></fa-icon>
        <fa-icon icon="eye" showIcon></fa-icon>
      </next-password-input>
    </div>
  </div>

  <div class="mb-3">
    <next-checkbox [switch]="true" [label]="'auth.vat-number-user' | translate" formControlName="isPIva"></next-checkbox>
  </div>

  <div class="text-center pt-3">
    <button class="btn btn-rounded btn-primary" [isLoading]="isLoading" [disabled]="isLoading">
      {{'auth.submit-signup-text' | translate}}
    </button>

    <div class="mt-2">
      <a class="text-danger" routerLink="/login">{{'auth.login-link-text' | translate}}</a>
    </div>
  </div>

</form>

