<div class="row mb-5">

  <div class="col-4">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title mb-3">
          {{'dashboard.statistics' | translate}}
        </h5>
      </div>
    </div>
  </div>

  <div class="col-4">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title mb-3">
          {{'dashboard.statistics' | translate}}
        </h5>
      </div>
    </div>
  </div>

  <div class="col-4">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title mb-3">
          {{'dashboard.open-tickets' | translate}}
        </h5>
      </div>
    </div>
  </div>
</div>

<app-property-table></app-property-table>
