import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, exhaustMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { nextAuthActions } from '@next/next-angular-kit';
import { AdminAccountService } from '@netserv/kalivah-angular-kit';
import { appActions } from '../actions/app.actions';
import { Store } from '@ngrx/store';
import { selectGooglePlacesIsLoaded } from '../selectors/app.selectors';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppEffects {

  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly httpClient: HttpClient,
    private readonly adminAccountService: AdminAccountService
  ) {
  }

  /**
   * On login and when current user info change, check if account is complete
   */
  checkAccountIsComplete$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.checkAccount, nextAuthActions.loginComplete, nextAuthActions.setUserManually),
    exhaustMap(() => {
      return this.adminAccountService.isComplete().pipe( // Call service to get AccessToken
        map(isComplete => appActions.checkAccountComplete({ isComplete })),
        catchError(() => of(appActions.checkAccountComplete({ isComplete: false })))
      );
    })
  ));

  /**
   * Load Google places API
   * https://developers.google.com/maps/documentation/javascript/places?hl=it#GetStarted
   */
  loadGooglePlacesApi$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.loadGooglePlacesApi),
    concatMap(() => this.store$.select(selectGooglePlacesIsLoaded)),  // Get current info
    exhaustMap(isLoaded => {
      if (!isLoaded) {
        const API_GOOGLE = `https://maps.googleapis.com/maps/api/js?key=${environment.googlePlacesKey}&libraries=places`;
        return this.httpClient.jsonp(API_GOOGLE, 'callback').pipe(
          map(() => appActions.loadGooglePlacesApiComplete({ googlePlacesIsLoaded: true })),
          catchError(() => of(appActions.loadGooglePlacesApiComplete({ googlePlacesIsLoaded: false })))
        );
      }
      return of(appActions.loadGooglePlacesApiComplete({ googlePlacesIsLoaded: true }));
    })
  ));

}
