import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  Input,
  Optional,
  Renderer2,
  Self,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbInputDatepicker, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AbstractFormComponent, NextSpinnerComponent } from '@next/next-angular-kit';
import { AdminUsersService, UserListFilter, UserListItemResponse, UserRoleType } from '@netserv/kalivah-angular-kit';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  merge,
  Observable,
  of,
  OperatorFunction,
  Subject,
  switchMap,
  tap
} from 'rxjs';
import { NgControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-user-autocomplete',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, NgbTypeahead, TranslateModule, ReactiveFormsModule, NgbInputDatepicker, NextSpinnerComponent],
  templateUrl: './user-autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAutocompleteComponent extends AbstractFormComponent<UserListItemResponse> implements DoCheck {

  /**
   * The input placeholder
   */
  @Input() placeholder = '';

  /**
   * Filter search by user role
   */
  @Input() roleFilter: UserRoleType = UserRoleType.PropertyManager;

  searchFailed = false;

  @ViewChild('instance', { static: true }) protected instance!: NgbTypeahead;
  protected click$ = new Subject<string>();

  constructor(
    protected override readonly _renderer: Renderer2,
    protected override readonly _elementRef: ElementRef,
    @Self() @Optional() protected override readonly _ngControl: NgControl,
    protected override readonly _translateService: TranslateService,
    protected override readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly adminUsersService: AdminUsersService
  ) {
    super(_renderer, _elementRef, _ngControl, _translateService, _changeDetectorRef);
  }

  searchUsers: OperatorFunction<string, readonly UserListItemResponse[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(300), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));

    return merge(debouncedText$, clicksWithClosedPopup$).pipe(
      tap(() => this.searchFailed = false),
      switchMap(text => this.adminUsersService.list({
        page: 0, pageSize: !text ? 10 : 30,
        query: text,
        filters: [UserListFilter.FullName],
        activeOnly: true,
        role: this.roleFilter
      })),
      map(res => res.data),
      tap(res => this.searchFailed = !res.length),
      catchError(() => {
        this.searchFailed = true;
        return of([]);
      }),
      finalize(() => {
        this._changeDetectorRef.detectChanges();
      })
    );
  };

  searchUsersFormatter = (result: UserListItemResponse) => `${result.firstName} ${result.lastName}`;

}
