import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent, TableEvent, TableHeader } from '../../core/table/table.component';
import { finalize, switchMap } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { KeyValuePair, PaginatedResponse } from '@next/next-angular-kit';
import {
  UserRole,
  UserListFilter,
  UserListItemResponse,
  UserListRequest, AdminUsersService
} from '@netserv/kalivah-angular-kit';
import { ToastService } from '../../../services/toast/toast.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateRangePickerComponent } from '../../core/date-range-picker/date-range-picker.component';

@Component({
  selector: 'app-user-table',
  standalone: true,
  imports: [CommonModule, TableComponent, TranslateModule, RouterLink, FontAwesomeModule, FormsModule, ReactiveFormsModule, DateRangePickerComponent],
  templateUrl: './user-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserTableComponent implements OnInit {

  /**
   * The users
   */
  protected paginatedUsers?: PaginatedResponse<UserListItemResponse>;

  /**
   * Loading status
   */
  protected isLoading?: boolean;
  protected isLoadingEnabled?: boolean;

  protected tableHeader: TableHeader = [
    { title: 'users.lastname', sortable: { column: 'Surname' } },
    { title: 'users.firstname', sortable: { column: 'Name' } },
    { title: 'users.role' },
    { title: 'users.registered-at', sortable: { column: 'Date', direction: 'desc' } },
    { title: 'general.enable' },
    { title: '' }
  ];

  protected searchFilters: Array<KeyValuePair> = [
    { key: UserListFilter.FullName, value: 'users.firstname' },
    { key: UserListFilter.TaxCode, value: 'users.tax-code' },
    { key: UserListFilter.VatNumber, value: 'users.vat-number' }
  ];

  protected roles: Array<UserRole> = [];
  protected customFilterForm: FormGroup;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly adminUsersService: AdminUsersService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.customFilterForm = this.formBuilder.group({
      registrationDate: [null],
      role: [null]
    });
  }

  ngOnInit() {
    this.adminUsersService.roles().subscribe(roles => this.roles = roles);
  }

  /**
   * Retrieve the users
   */
  getUsers(tableEvent: TableEvent): void {
    this.isLoading = true;

    const filter: UserListRequest = {
      filters: tableEvent.filterKeys as Array<UserListFilter>,
      query: tableEvent.filter,
      role: tableEvent.customFilterValue?.role,
      from: tableEvent.customFilterValue?.registrationDate?.from,
      to: tableEvent.customFilterValue?.registrationDate?.to,
      page: tableEvent.pageNumber,
      pageSize: tableEvent.pageSize,
      sorter: tableEvent.sort?.column,
      descending: tableEvent.sort?.direction === 'desc'
    };

    this.adminUsersService.list(filter).pipe(
      finalize(() => {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      })
    ).subscribe(res => {
      this.paginatedUsers = res;
    });
  }

  /**
   * Enable disable an user
   * @param event
   * @param user
   */
  toggleEnableUser(event: Event, user: UserListItemResponse): void {
    event.preventDefault();
    const enabled = !!(event.target as HTMLInputElement)?.checked;
    this.isLoadingEnabled = true;
    this.adminUsersService.enable(user.id, enabled).pipe(
      switchMap(() => this.translateService.get(enabled ? 'users.enabled-success' : 'users.disabled-success')),
      finalize(() => {
        this.isLoadingEnabled = false;
        this.changeDetectorRef.detectChanges();
      })
    ).subscribe(label => {
      user.enabled = enabled;
      this.toastService.success(label);
    });
  }
}
