import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {
  AccommodationPriceListItemResponse,
  AdminAccommodationsService,
  QueryPaginatedSortedRequest
} from '@netserv/kalivah-angular-kit';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AccommodationPriceUpsertModalComponent
} from '../accommodation-price-upsert-modal/accommodation-price-upsert-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TableComponent, TableEvent, TableHeader } from '../../core/table/table.component';
import { PaginatedResponse } from '@next/next-angular-kit';
import { finalize, tap } from 'rxjs';
import { RouterLink } from '@angular/router';
import { DeleteModalComponent } from '../../core/delete-modal/delete-modal.component';

@Component({
  selector: 'app-accommodation-price-table[accommodationId]',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule, TableComponent, RouterLink],
  providers: [DatePipe],
  templateUrl: './accommodation-price-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccommodationPriceTableComponent {

  /**
   * The accommodation id
   */
  @Input() accommodationId!: number;

  /**
   * The prices
   */
  protected paginatedPrices?: PaginatedResponse<AccommodationPriceListItemResponse>;

  /**
   * Loading status
   */
  protected isLoading?: boolean;

  protected tableHeader: TableHeader = [
    { title: 'properties.accommodations.prices.price-start-day', sortable: { column: 'StartDay', direction: 'desc' } },
    { title: 'properties.accommodations.prices.price-per-night' },
    { title: 'properties.accommodations.prices.additional-price' },
    { title: '' }
  ];

  @ViewChild('table', { static: true }) table!: TableComponent;

  constructor(
    private readonly datePipe: DatePipe,
    private readonly modalService: NgbModal,
    private readonly adminAccommodationsService: AdminAccommodationsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly translateService: TranslateService
  ) {
  }

  getPrices(tableEvent: TableEvent) {
    this.isLoading = true;

    const filter: QueryPaginatedSortedRequest = {
      page: tableEvent.pageNumber,
      pageSize: tableEvent.pageSize,
      sorter: tableEvent.sort?.column,
      descending: tableEvent.sort?.direction === 'desc'
    };

    this.adminAccommodationsService.priceList(this.accommodationId, filter).pipe(
      finalize(() => {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      })
    ).subscribe(res => {
      this.paginatedPrices = res;
    });
  }

  openPriceUpsertModal(price?: AccommodationPriceListItemResponse) {
    const modalRef = this.modalService.open(AccommodationPriceUpsertModalComponent);
    modalRef.componentInstance.accommodationId = this.accommodationId;
    modalRef.componentInstance.price = price;

    modalRef.result.then(() => this.table.reload(), () => void 0);
  }

  openDeletePriceModal(price: AccommodationPriceListItemResponse): void {
    this.translateService.get('properties.accommodations.prices.delete-message', {
      startDay: this.datePipe.transform(price.startDay)
    }).subscribe(label => {
      const modalRef = this.modalService.open(DeleteModalComponent);
      modalRef.componentInstance.bodyString = label;
      modalRef.componentInstance.submitAction = this.adminAccommodationsService.deletePrice(this.accommodationId, price.id).pipe(
        tap(() => {
          this.table.reload();
        })
      );
    });
  }
}
