import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordGuard } from '@next/next-angular-kit';
import { SignUpComponent } from './sign-up/sign-up.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    data: {
      isLarge: true
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [ResetPasswordGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        loadComponent: () => import('./_layouts/core-layout/core-layout.component').then(m => m.CoreLayoutComponent),
        children: routes
      },
      {
        path: 'not-found',
        loadComponent: () => import('./error/error.component').then(m => m.ErrorComponent),
        data: {
          errorCode: 404
        }
      },
      {
        path: 'forbidden',
        loadComponent: () => import('./error/error.component').then(m => m.ErrorComponent),
        data: {
          errorCode: 403
        }
      },
      {
        path: 'server-error',
        loadComponent: () => import('./error/error.component').then(m => m.ErrorComponent),
        data: {
          errorCode: 500
        }
      }
    ])
  ],
  exports: [RouterModule]
})
export class CoreRoutingModule {
}
