import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NextLoadingButtonComponent } from '@next/next-angular-kit';
import { finalize, Observable } from 'rxjs';
import { ToastService } from '../../../services/toast/toast.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-delete-modal',
  standalone: true,
  imports: [CommonModule, TranslateModule, NextLoadingButtonComponent, FontAwesomeModule],
  templateUrl: './delete-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteModalComponent {

  /**
   * Custom title (i18n key or custom string)
   */
  @Input() i18nTitle: string | undefined;

  /**
   * The string to display inside body
   */
  @Input() bodyString: string | undefined;

  /**
   * Body template
   */
  @Input() bodyTemplate: TemplateRef<any> | undefined;

  /**
   * The action on submit click
   */
  @Input() submitAction?: Observable<any>;

  /**
   * Loading status
   */
  protected isLoading = false;

  constructor(
    protected readonly activeModal: NgbActiveModal,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
  }

  submit(): void {
    if (!this.submitAction) {
      return this.activeModal.close(null);
    }

    this.isLoading = true;
    this.submitAction.pipe(
      finalize(() => {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      })
    ).subscribe(res => {
      this.translateService.get('general.delete-modal-success-message').subscribe(label => {
        this.toastService.success(label);
      });
      this.activeModal.close(res);
    });
  }
}
