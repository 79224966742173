import { Injectable } from '@angular/core';
import { filter, Observable, Subject } from 'rxjs';

export interface ToastInfo {
  text: string;
  header?: string;
  type: 'standard' | 'success' | 'error' | 'info' | 'warning';
  autoHide?: boolean;
  delay?: number;
}

@Injectable({ providedIn: 'root' })
export class ToastService {
  private subject = new Subject<ToastInfo>();

  /**
   * Listen on toast arrived
   * @param filterType filter type of notification
   */
  public onToast(filterType?: 'standard' | 'success' | 'error' | 'info' | 'warning'): Observable<ToastInfo> {
    return this.subject.asObservable().pipe(
      filter(n => n && (!filterType || (n.type === filterType)))
    );
  }

  /**
   * Add the toast
   * @param toast
   */
  toast(toast: ToastInfo): void {
    this.subject.next(toast);
  }

  /**
   * Add standard toast
   * @param text toast text
   * @param header toast header text
   * @param autoHide if auto hide toast
   * @param delay delay
   */
  standard(text: string, header?: string, autoHide?: boolean, delay?: number): void {
    this.toast({ text, header, type: 'standard', autoHide, delay });
  }

  /**
   * Add success toast
   * @param text toast text
   * @param header toast header text
   * @param autoHide if auto hide toast
   * @param delay delay
   */
  success(text: string, header?: string, autoHide?: boolean, delay?: number): void {
    this.toast({ text, header, type: 'success', autoHide, delay });
  }

  /**
   * Add error toast
   * @param text toast text
   * @param header toast header text
   * @param autoHide if auto hide toast
   * @param delay delay
   */
  error(text: string, header?: string, autoHide?: boolean, delay?: number): void {
    this.toast({ text, header, type: 'error', autoHide, delay });
  }

  /**
   * Add warning toast
   * @param text toast text
   * @param header toast header text
   * @param autoHide if auto hide toast
   * @param delay delay
   */
  warning(text: string, header?: string, autoHide?: boolean, delay?: number): void {
    this.toast({ text, header, type: 'warning', autoHide, delay });
  }

  /**
   * Add info toast
   * @param text toast text
   * @param header toast header text
   * @param autoHide if auto hide toast
   * @param delay delay
   */
  info(text: string, header?: string, autoHide?: boolean, delay?: number): void {
    this.toast({ text, header, type: 'info', autoHide, delay });
  }
}
