import { NgbDate, NgbDateParserFormatter, NgbDatepickerI18nDefault, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationWidth } from '@angular/common';

@Injectable()
export class AppDatepickerI18n extends NgbDatepickerI18nDefault {

  private currentLanguages?: {
    weekdays: Array<string>,
    months: Array<string>,
    shorts: Array<string>,
    week: string
  };

  constructor(
    private readonly translateService: TranslateService
  ) {
    super('en');
    this.translateService.get('datepicker').subscribe(datepicker => {
      this.currentLanguages = datepicker;
    });
  }

  override getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return this.currentLanguages?.weekdays[weekday - 1] ?? super.getWeekdayLabel(weekday, width);
  }

  override getWeekLabel(): string {
    return this.currentLanguages?.week ?? super.getWeekLabel();
  }

  override getMonthShortName(month: number): string {
    return this.currentLanguages?.shorts[month - 1] ?? super.getMonthShortName(month);
  }

  override getMonthFullName(month: number): string {
    return this.currentLanguages?.months[month - 1] ?? super.getMonthFullName(month);
  }

  override getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}/${date.month}/${date.year}`;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class AppDateParserFormatter extends NgbDateParserFormatter {

  constructor(
    private readonly translateService: TranslateService
  ) {
    super();
  }

  /**
   * Convert NgbDateStruct to Date
   * @param date
   */
  public static ngbDateToDate(date: NgbDate | NgbDateStruct | null): Date | null {
    if (!date) {
      return null;
    }
    return new Date(date.year, date.month - 1, date.day);
  }

  /**
   * Convert NgbDateStruct to iso string
   * @param date
   */
  public static ngbDateToIsoString(date: NgbDate | NgbDateStruct | null): string | null {
    if (!date) {
      return null;
    }
    return new Date(date.year, date.month - 1, date.day)?.toISOString();
  }

  /**
   * Convert iso string to NgbDateStruct
   * @param isoString
   */
  public static isoStringToNgbDateStruct(isoString: string | null): NgbDateStruct | null {
    if (!isoString) {
      return null;
    }
    return AppDateParserFormatter.dateToNgbDateStruct(new Date(Date.parse(isoString)));
  }

  /**
   * Convert date to NgbDateStruct
   * @param date
   */
  public static dateToNgbDateStruct(date: Date | null): NgbDateStruct | null {
    if (!date) {
      return null;
    }
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  /**
   * Try parse user input to NgbDateStruct
   * @param value
   */
  parse(value: string | null): NgbDateStruct | null {
    if (!value) {
      return null;
    }

    if (value.includes('-')) {
      const date = value.split('-');
      return {
        day: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[0], 10),
      };
    }

    const date = value.split('/');
    return {
      day: parseInt(date[0], 10),
      month: parseInt(date[1], 10),
      year: parseInt(date[2], 10),
    };
  }

  /**
   * Format the NgbDateStruct to string
   * @param date
   */
  format(date: NgbDateStruct | null): string {
    if (!date) {
      return '';
    }
    if (this.translateService.currentLang !== 'it') {
      return `${date.year}-${this.roundNumber(date.month)}-${this.roundNumber(date.day)}`;
    }
    return `${this.roundNumber(date.day)}/${this.roundNumber(date.month)}/${date.year}`;
  }

  private roundNumber(num?: number): string {
    return num?.toLocaleString('it', {
      minimumIntegerDigits: 2,
      useGrouping: false
    }) ?? '';
  }

}
