import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyTableComponent } from '../../../components/properties/property-table/property-table.component';
import { AdminUsersService, UserRoleType } from '@netserv/kalivah-angular-kit';
import { first } from 'rxjs';

@Component({
  selector: 'app-properties',
  standalone: true,
  imports: [CommonModule, PropertyTableComponent],
  templateUrl: './properties.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertiesComponent {

  /**
   * Show/hide property id filter
   */
  showPropertyIdFilter = false;

  constructor(
    private readonly adminUsersService: AdminUsersService
  ) {
    this.adminUsersService.userHasRole(UserRoleType.Administrator).pipe(
      first()
    ).subscribe(isAdmin => {
      this.showPropertyIdFilter = isAdmin;
    });
  }
}
