<div class="container-fluid text-center" *ngIf="isLoading; else loaded">
  <next-spinner></next-spinner>
</div>

<ng-template #loaded>
  <div class="d-flex flex-column gap-4">
    <app-property-info-form [property]="property" [userId]="userId"></app-property-info-form>

    <ng-container *ngIf="property">
      <app-property-cadastral-form [propertyId]="property.id"></app-property-cadastral-form>
      <app-property-description-form [propertyId]="property.id" [descriptions]="property.descriptions"></app-property-description-form>
      <app-property-image-gallery-form [propertyId]="property.id" [images]="property.images"></app-property-image-gallery-form>
      <app-accommodation-table [propertyId]="property.id"></app-accommodation-table>
    </ng-container>

  </div>
</ng-template>
