import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Optional,
  Renderer2,
  Self,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AbstractFormComponent } from '@next/next-angular-kit';
import { GeoInfo } from '@netserv/kalivah-angular-kit';
import { Store } from '@ngrx/store';
import { selectGooglePlacesIsLoaded } from '../../../store/selectors/app.selectors';
import { appActions } from '../../../store/actions/app.actions';

@Component({
  selector: 'app-geo-info-autocomplete',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
  templateUrl: './geo-info-autocomplete.component.html',
  styleUrls: ['./geo-info-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeoInfoAutocompleteComponent extends AbstractFormComponent<GeoInfo | null> implements OnInit {
  protected apiReady?: boolean;

  private autocomplete?: google.maps.places.Autocomplete;
  @ViewChild('search') private readonly searchElementRef!: ElementRef;

  constructor(
    protected override readonly _renderer: Renderer2,
    protected override readonly _elementRef: ElementRef,
    @Self() @Optional() protected override readonly _ngControl: NgControl,
    protected override readonly _translateService: TranslateService,
    protected override readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly store$: Store
  ) {
    super(_renderer, _elementRef, _ngControl, _translateService, _changeDetectorRef);
  }

  override ngOnInit() {
    super.ngOnInit();

    this.store$.dispatch(appActions.loadGooglePlacesApi());
    this.store$.select(selectGooglePlacesIsLoaded).subscribe(isLoaded => {
      this.apiReady = isLoaded;
      if (this.apiReady) {
        this.initAutocomplete();
        this.updateInfoAutocomplete();
      }
      this._changeDetectorRef.detectChanges();
    });
  }

  override writeValue(value: GeoInfo | null) {
    super.writeValue(value);
    this.updateInfoAutocomplete();
  }

  private updateInfoAutocomplete(): void {
    const value: GeoInfo | null = this.control.value;
    if (!value) {
      return;
    }

    if (this.searchElementRef) {
      (this.searchElementRef.nativeElement as HTMLInputElement).value = value.address;
    }

    if (this.autocomplete && value.latitude && value.longitude) {
      this.autocomplete.setBounds(new google.maps.LatLngBounds({ // Center to Current value
        lat: value.latitude,
        lng: value.longitude
      }));
    }
  }

  private initAutocomplete(): void {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement,
      {
        bounds: new google.maps.LatLngBounds({ // Center to Rome
          lat: 41.902782,
          lng: 12.496366
        })
      }
    );

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete?.getPlace();
      if (!place?.geometry) {
        this.control.setValue(null);
        this._changeDetectorRef.markForCheck();
        this._changeDetectorRef.detectChanges();
        return;
      }

      const geoInfo: GeoInfo = {
        latitude: place.geometry.location?.lat() ?? 0,
        longitude: place.geometry.location?.lng() ?? 0,
        address: place.formatted_address ?? '',
        city: '',
        zipCode: '',
        district: ''
      };

      place.address_components?.forEach(f => {
        if (f.types.includes('administrative_area_level_3')) {
          geoInfo.city = f.short_name;
        } else if (f.types.includes('administrative_area_level_2')) {
          geoInfo.district = f.short_name;
        } else if (f.types.includes('postal_code')) {
          geoInfo.zipCode = f.long_name;
        } else if (f.types.includes('locality') && !geoInfo.city) {
          geoInfo.city = f.short_name;
        }
      });

      this.control.setValue(geoInfo);
      this._changeDetectorRef.markForCheck();
      this._changeDetectorRef.detectChanges();
    });
  }
}
