<div class="row mb-5 justify-content-between">
  <div class="col-4" *ngIf="showFilter">
    <div class="input-group flex-nowrap">
      <span class="input-group-text" id="search" *ngIf="!searchFilters || !searchFilters.length; else showFilterKeys">
        <fa-icon icon="magnifying-glass"></fa-icon>
      </span>
      <ng-template #showFilterKeys>
        <div ngbDropdown [autoClose]="'outside'" class="input-group-text">
          <button class="btn p-0" type="button" ngbDropdownToggle>
            {{
            !filterParams.filterKeys.length ? ('general.all-filters' | translate) :
              ('general.elements-selected' | translate : { elements: filterParams.filterKeys.length })
            }}
          </button>
          <div ngbDropdownMenu class="dropdown-menu">
            <div ngbDropdownItem *ngFor="let searchFilter of searchFilters">
              <div class="form-check">
                <input class="form-check-input" type="checkbox"
                       (change)="toggleFilterKey($event, searchFilter)"
                       [value]="searchFilter.key" id="filter-by-{{searchFilter.key}}">
                <label class="form-check-label" for="filter-by-{{searchFilter.key}}">
                  {{searchFilter.value | translate}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <input type="text" class="form-control"
             [placeholder]="'general.search' | translate"
             [attr.aria-label]="'general.search' | translate"
             aria-describedby="search"
             [formControl]="filter" />
    </div>
  </div>

  <div class="col-6 d-flex justify-content-end gap-3">
    <ng-content select="[customFilters]"></ng-content>
  </div>
</div>

<table class="table table-striped mb-0" aria-label="table">
  <thead>
  <tr>
    <ng-container *ngFor="let h of header">
      <th *ngIf="h.sortable; else simpleHead"
          scope="col"
          [sortable]="h.sortable.column"
          [direction]="h.sortable.direction"
          (sort)="onSort($event)">
        {{h.title | translate}}
      </th>
      <ng-template #simpleHead>
        <th scope="col">
          {{h.title | translate}}
        </th>
      </ng-template>
    </ng-container>
  </tr>
  </thead>
  <tbody>
  <tr *ngIf="isLoading; else showData">
    <td [attr.colspan]="header.length" class="text-center">
      <next-spinner></next-spinner>
    </td>
  </tr>

  <ng-template #showData>
    <tr *ngIf="!totalCount; else tableData">
      <td [attr.colspan]="header.length" class="text-center">
        <span #customEmptyText>
          <ng-content select="[emptyText]"></ng-content>
        </span>
        <ng-container *ngIf="!customEmptyText.hasChildNodes()">
          {{'general.empty-table' | translate}}
        </ng-container>
      </td>
    </tr>
    <ng-template #tableData>
      <ng-content></ng-content>
    </ng-template>
  </ng-template>
  </tbody>

</table>

<div *ngIf="totalCount && totalCount > 0" class="text-end mt-1">
  <small class="text-muted mt-1 me-2">
    {{'general.total-elements' | translate: {total: totalCount} }}
  </small>
</div>

<div class="d-flex justify-content-between align-items-center p-2 pt-0 mt-3">
  <ngb-pagination *ngIf="totalPages && totalCount && totalPages > 1"
                  [collectionSize]="totalCount"
                  [page]="filterParams.pageNumber + 1"
                  [rotate]="true"
                  [maxSize]="5"
                  [pageSize]="filterParams.pageSize"
                  (pageChange)="changePage($event)"></ngb-pagination>

  <select class="form-select form-select-sm w-auto ms-auto"
          [(ngModel)]="filterParams.pageSize" (ngModelChange)="changePage()">
    <option *ngFor="let value of [10, 25, 50, 100]" [ngValue]="value">
      {{'general.items-per-page' | translate : { value: value } }}
    </option>
  </select>
</div>
