<form [formGroup]="formGroup" (ngSubmit)="submit()">
  <div class="row">
    <div class="col-sm-12 col-md-6 mb-3">
      <next-input [label]="'users.firstname' | translate" formControlName="firstName"></next-input>
    </div>
    <div class="col-sm-12 col-md-6 mb-3">
      <next-input [label]="'users.lastname' | translate" formControlName="lastName"></next-input>
    </div>
  </div>

  <div class="row" *ngIf="!isAdmin">
    <div class="col-sm-12 col-md-6 mb-3">
      <app-datepicker [label]="'users.birth-date' | translate"
                      formControlName="birthDate"
                      [max]="maxBirthDate"></app-datepicker>
    </div>
    <div class="col-sm-12 col-md-6 mb-3">
      <next-input [label]="'users.tax-code' | translate" formControlName="cf"></next-input>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6 mb-3">
      <app-geo-info-autocomplete [label]="'users.address' | translate" formControlName="geo"></app-geo-info-autocomplete>
    </div>
    <div class="col-sm-12 col-md-6 mb-3">
      <app-country-select [label]="'users.nationality' | translate"
                          formControlName="nationality"></app-country-select>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6 mb-3">
      <next-input type="email" [label]="'users.email' | translate" formControlName="email"></next-input>
    </div>
    <div class="col-sm-12 col-md-6 mb-3">
      <next-input type="tel" [label]="'users.phone-number' | translate"
                  formControlName="phoneNumber"></next-input>
    </div>
  </div>

  <ng-container *ngIf="!isAdmin">
    <div class="row">
      <div class="col-sm-12 col-md-6 mb-3">
        <next-input label="IBAN" formControlName="iban"></next-input>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <next-input [label]="'users.account-holder' | translate" formControlName="accountHolder"></next-input>
      </div>
    </div>

    <div class="mb-3">
      <next-checkbox [switch]="true" [label]="'auth.vat-number-user' | translate"
                     formControlName="isPIva"></next-checkbox>
    </div>

    <div class="row" [ngbCollapse]="!formGroup.get('isPIva')?.value">
      <div class="col-sm-12 col-md-4 mb-3">
        <next-input [label]="'users.vat-number' | translate" formControlName="pIva"></next-input>
      </div>
      <div class="col-sm-12 col-md-4 mb-3">
        <next-input [label]="'users.business-name' | translate" formControlName="businessName"></next-input>
      </div>
      <div class="col-sm-12 col-md-4 mb-3">
        <next-input type="email" [label]="'users.business-email' | translate"
                    formControlName="businessEmail"></next-input>
      </div>
    </div>

  </ng-container>

  <div class="d-flex justify-content-end mt-4">
    <button class="btn btn-sm btn-secondary" type="submit" [isLoading]="isLoading"
            [disabled]="isLoading || formGroup.pristine">
      <fa-icon *ngIf="!isLoading" icon="paper-plane" class="me-1"></fa-icon>
      {{(this.user ? 'next.general.edit' : 'next.general.save') | translate}}
    </button>
  </div>
</form>
