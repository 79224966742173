<div class="row">
  <div class="col-6">
    <label *ngIf="label || endLabel" [for]="id" class="form-label">
      {{label || endLabel}}
    </label>

    <div class="dp-hidden position-absolute">
      <div class="input-group">
        <input #datepicker="ngbDatepicker"
               class="form-control"
               ngbDatepicker
               [autoClose]="'outside'"
               [minDate]="minDate!"
               [maxDate]="maxDate!"
               (dateSelect)="onDateSelection($event)"
               (closed)="onClosed()"
               [displayMonths]="2"
               [dayTemplate]="t"
               outsideDays="hidden"
               tabindex="-1" />
        <ng-template #t let-date let-focused="focused">
					<span class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="setHoverDate(date)"
                (mouseleave)="setHoverDate(null)">
						{{ date.day }}
					</span>
        </ng-template>
      </div>
    </div>

    <div class="input-group" [class.has-validation]="isValid || isInvalid">
      <input #dpFromDate
             [id]="id"
             class="form-control"
             [class.is-valid]="isValid"
             [class.is-invalid]="isInvalid"
             [placeholder]="placeholder"
             (blur)="markAsTouched()"
             [value]="formatter.format(fromDate)"
             (input)="updateDateFromInput('from', dpFromDate.value)" />
      <button class="btn btn-outline-primary" (click)="datepicker.toggle()" type="button">
        <fa-icon icon="calendar-week"></fa-icon>
      </button>

      <div *ngIf="isInvalid" class="invalid-feedback">
        <div #customError>
          <ng-content select="[error]"></ng-content>
        </div>
        <ng-container *ngIf="!customError.hasChildNodes()">{{invalidMessage | async}}</ng-container>
      </div>
    </div>
  </div>

  <div class="col-6">
    <label *ngIf="endLabel || label" [for]="id + '-end'" class="form-label">
      {{endLabel || label}}
    </label>

    <div class="input-group">
      <input #dpToDate
             [id]="id + '-end'"
             class="form-control"
             [class.is-valid]="isValid"
             [class.is-invalid]="isInvalid"
             [placeholder]="placeholder"
             (blur)="markAsTouched()"
             [value]="formatter.format(toDate)"
             (input)="updateDateFromInput('to', dpToDate.value)" />

      <button class="btn btn-outline-primary" (click)="datepicker.toggle()" type="button">
        <fa-icon icon="calendar-week"></fa-icon>
      </button>
    </div>
  </div>
</div>

