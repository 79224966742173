<label *ngIf="label" [for]="id" class="form-label">{{label}}</label>
<div [class.input-group]="prepend.hasChildNodes() || append.hasChildNodes()"
     [class.has-validation]="isValid || isInvalid">

  <span class="input-group-text" #prepend>
    <ng-content select="[prepend]"></ng-content>
  </span>

  <input #search
         [id]="id"
         class="form-control"
         [class.is-valid]="isValid"
         [class.is-invalid]="isInvalid"
         (blur)="markAsTouched()" />

  <span class="input-group-text" #append>
    <ng-content select="[append]"></ng-content>
  </span>

  <div *ngIf="isInvalid" class="invalid-feedback">
    <div #customError>
      <ng-content select="[error]"></ng-content>
    </div>
    <ng-container *ngIf="!customError.hasChildNodes()">{{invalidMessage | async}}</ng-container>
  </div>
</div>

<div *ngIf="apiReady === false" class="form-text text-danger">{{'errors.service-unavailable' | translate}}</div>
