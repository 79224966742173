<div class="modal-header">
  <h4 class="modal-title">{{'properties.accommodations.prices.price-settings' | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>

<form [formGroup]="formGroup" (ngSubmit)="submit()">
  <div class="modal-body d-flex flex-column gap-4">

    <app-datepicker *ngIf="price"
                    [label]="'properties.accommodations.prices.price-start-day' | translate"
                    formControlName="startDay"></app-datepicker>

    <app-date-range-picker *ngIf="!price"
                           formControlName="rangeDate"
                           [label]="'properties.accommodations.prices.price-start-day' | translate"
                           [endLabel]="'properties.accommodations.prices.price-end-day' | translate"></app-date-range-picker>

    <next-input type="number"
                formControlName="feePerStay"
                [label]="'properties.accommodations.prices.price-per-night' | translate">
      <fa-icon icon="euro-sign" prepend></fa-icon>
    </next-input>

    <next-input type="number"
                formControlName="flatFeePerDay"
                [label]="'properties.accommodations.prices.additional-price' | translate">
      <fa-icon icon="euro-sign" prepend></fa-icon>
    </next-input>

  </div>

  <div class="modal-footer d-flex justify-content-end">
    <button type="submit" class="btn btn-sm btn-secondary" [isLoading]="isLoading"
            [disabled]="isLoading || formGroup.pristine">
      <fa-icon *ngIf="!isLoading" icon="paper-plane" class="me-1"></fa-icon>
      {{'next.general.save' | translate}}
    </button>
  </div>
</form>
