<ngb-toast *ngFor="let toast of toasts"
           [class]="getToastClass(toast)"
           [header]="toast.header || ''"
           [autohide]="toast.autoHide === undefined ? this.autoHide : toast.autoHide"
           [delay]="toast.delay || this.delay"
           (hidden)="remove(toast)">

  <div class="d-flex">
    {{toast.text}}

    <button *ngIf="!toast.header"
            type="button"
            class="btn-close me-2 ms-auto"
            [class.btn-close-white]="toast.type !== 'standard'"
            aria-label="Close"
            (click)="remove(toast)"></button>
  </div>

</ngb-toast>
