import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AdminPropertiesService, PropertyDescription } from '@netserv/kalivah-angular-kit';
import { FormArray, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { forkJoin, Observable, switchMap } from 'rxjs';
import { NextLoadingButtonComponent } from '@next/next-angular-kit';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { KalivahFormComponent } from '../../../abstracts/kalivah-form-component';

@Component({
  selector: 'app-property-description-form[descriptions][propertyId]',
  standalone: true,
  imports: [CommonModule, TranslateModule, FontAwesomeModule, FormsModule, ReactiveFormsModule, NextLoadingButtonComponent, NgbNavModule],
  templateUrl: './property-description-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyDescriptionFormComponent extends KalivahFormComponent<string> implements OnChanges {

  /**
   * The property id
   */
  @Input() propertyId!: number;

  /**
   * The property descriptions
   */
  @Input() descriptions!: Array<PropertyDescription>;

  constructor(
    private readonly adminPropertiesService: AdminPropertiesService
  ) {
    super();
  }

  get descriptionsForm(): Array<FormGroup> {
    return (this.formGroup.get('descriptions') as FormArray).controls as Array<FormGroup>;
  }

  protected initForm(): FormGroup {
    const langs = this.translateService.getLangs();
    return this.formBuilder.group({
      descriptions: this.formBuilder.array(langs.map(lang => this.formBuilder.group({
        languageCode: [lang, Validators.required],
        description: [null]
      })))
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.descriptions) {
      this.descriptionsForm.forEach(form => {
        const langCode = form.get('languageCode')?.value;
        const propertyDescription = this.descriptions.find(d => d.language === langCode);
        form.patchValue({ description: propertyDescription?.description });
      });
    }
  }

  protected submitAction(formValue: any): Observable<string> {
    const controls = (this.formGroup.get('descriptions') as FormArray).controls
      .filter(control => control.dirty);

    const descriptions$: Array<Observable<void>> = controls.map(control => {
      const val: { languageCode: string, description: string } = control.value;
      return this.adminPropertiesService.updateDescription(this.propertyId, val.languageCode, val.description);
    });

    return forkJoin(descriptions$).pipe(switchMap(() => this.translateService.get('properties.update-success')));
  }

  protected submitComplete(res: string): void {
    this.toastService.success(res);
  }

}
