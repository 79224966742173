<div class="card">
  <div class="card-body">
    <form [formGroup]="formGroup" (ngSubmit)="submit()">

      <h5 class="card-title mb-3">
        {{'properties.accommodations.info' | translate}}
      </h5>

      <div class="row mt-3">
        <div class="col-sm-12 col-md-6">
          <next-input [label]="'properties.accommodations.name' | translate" formControlName="name"></next-input>
        </div>
        <div class="col-sm-12 col-md-6">
          <next-input type="number"
                      [min]="1"
                      [label]="'properties.accommodations.capacity' | translate"
                      formControlName="capacity"></next-input>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-sm-12 col-md-4">
          <next-input type="number"
                      [min]="0"
                      [label]="'properties.accommodations.single-beds' | translate"
                      formControlName="singleBeds"></next-input>
        </div>
        <div class="col-sm-12 col-md-4">
          <next-input type="number"
                      [min]="0"
                      [label]="'properties.accommodations.king-size-beds' | translate"
                      formControlName="kingSizeBeds"></next-input>
        </div>
        <div class="col-sm-12 col-md-4">
          <next-input type="number"
                      [min]="0"
                      [label]="'properties.accommodations.queen-size-beds' | translate"
                      formControlName="queenSizeBeds"></next-input>
        </div>
      </div>

      <div class="mt-3">
        <app-accommodation-service-search formControlName="services"></app-accommodation-service-search>
      </div>

      <div class="text-end mt-3">
        <button type="submit" class="btn btn-sm btn-secondary" [isLoading]="isLoading"
                [disabled]="isLoading || formGroup.pristine">
          <fa-icon *ngIf="!isLoading" icon="paper-plane" class="me-1"></fa-icon>
          {{'next.general.save' | translate}}
        </button>
      </div>
    </form>
  </div>
</div>
