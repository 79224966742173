<label [for]="id" class="form-label">{{label}}</label>
<select [id]="id"
        class="form-select"
        [class.is-valid]="isValid"
        [class.is-invalid]="isInvalid"
        (blur)="markAsTouched()"
        [attr.aria-label]="label"
        [formControl]="control">

  <option [value]="null" disabled selected>
    <next-spinner *ngIf="isLoading; else complete"></next-spinner>
    <ng-template #complete>{{'general.select-country' | translate}}</ng-template>
  </option>

  <ng-container *ngIf="options">
    <option *ngFor="let option of options" [value]="option.code">
      {{option.name}}
    </option>
  </ng-container>

</select>

<div *ngIf="isInvalid" class="invalid-feedback">
  <div #customError>
    <ng-content select="[error]"></ng-content>
  </div>
  <ng-container *ngIf="!customError.hasChildNodes()">{{invalidMessage | async}}</ng-container>
</div>
