import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, map, of, skipWhile, switchMap, tap } from 'rxjs';
import { selectAccountIsComplete } from '../store/selectors/app.selectors';
import { appActions } from '../store/actions/app.actions';
import { ToastService } from '../services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { AdminUsersService, UserRoleType } from '@netserv/kalivah-angular-kit';

export const AccountCompleteGuard: CanActivateFn = (route, state) => {
  const store$ = inject(Store),
    router = inject(Router),
    toastService = inject(ToastService),
    translateService = inject(TranslateService),
    adminUsersService = inject(AdminUsersService);

  return adminUsersService.userHasRole(UserRoleType.Administrator).pipe(
    switchMap(isAdmin => {
      if (isAdmin) {
        return of(true);
      }

      return store$.select(selectAccountIsComplete).pipe(
        tap(isComplete => {
          if (!isComplete) {
            store$.dispatch(appActions.checkAccount());
          }
        }),
        skipWhile(isComplete => isComplete === undefined),
        first(),
        map(isComplete => {
          if (isComplete) {
            return true;
          }
          router.navigate(['/management/profile']).then(() => {
            translateService.get('auth.incomplete-account-message').subscribe(label => {
              toastService.warning(label);
            });
          });
          return false;
        })
      );
    })
  );

};
