import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Optional,
  Renderer2,
  Self,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbInputDatepicker
} from '@ng-bootstrap/ng-bootstrap';
import { AbstractFormComponent } from '@next/next-angular-kit';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { AppDateParserFormatter } from '../../../utils/bs-datepicker';

@Component({
  selector: 'app-date-range-picker',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule, NgbInputDatepicker, ReactiveFormsModule],
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangePickerComponent extends AbstractFormComponent<{ from?: string, to?: string }> {

  /**
   * Label on end date
   */
  @Input() endLabel: string | undefined;

  /**
   * The input placeholder
   */
  @Input() placeholder = '';

  /**
   * The max date value
   * @default new Date(2090, 11, 31)
   * @example new Date()
   */
  @Input() max?: Date = new Date(2090, 11, 31);

  /**
   * The min date value
   * @default new Date(1900, 1, 1)
   * @example new Date()
   */
  @Input() min: Date | undefined = new Date(1900, 1, 1);

  @ViewChild('datepicker') datepicker!: NgbInputDatepicker;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;

  constructor(
    protected override readonly _renderer: Renderer2,
    protected override readonly _elementRef: ElementRef,
    @Self() @Optional() protected override readonly _ngControl: NgControl,
    protected override readonly _translateService: TranslateService,
    protected override readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly calendar: NgbCalendar,
    public readonly formatter: NgbDateParserFormatter
  ) {
    super(_renderer, _elementRef, _ngControl, _translateService, _changeDetectorRef);
  }

  override writeValue(value: { from?: string, to?: string }): void {
    super.writeValue(value);
    if (!value) {
      this.fromDate = null;
      this.toDate = null;
      return;
    }
    if (value.from) {
      this.fromDate = NgbDate.from(AppDateParserFormatter.isoStringToNgbDateStruct(value.from));
    } else {
      this.fromDate = null;
    }
    if (value.to) {
      this.toDate = NgbDate.from(AppDateParserFormatter.isoStringToNgbDateStruct(value.to));
    } else {
      this.toDate = null;
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  setHoverDate(date: NgbDate | null): void {
    this.hoveredDate = date;
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  updateDateFromInput(variable: 'from' | 'to', input: string): void {
    let date: NgbDate | null = null;
    const parsed = this.formatter.parse(input);
    if (input && parsed && this.calendar.isValid(NgbDate.from(parsed))) {
      date = NgbDate.from(parsed);
    }
    if (variable == 'to') {
      this.toDate = date;
    } else {
      this.fromDate = date;
    }

    if (this.fromDate && this.toDate) {
      this.onClosed();
    } else if (!this.fromDate && !this.toDate) {
      this.control.setValue({ from: null, to: null });
    }
  }

  onClosed(): void {
    let emit = !!this.fromDate && !!this.toDate;
    if (!this.fromDate || !this.toDate) {
      emit = !(!this.fromDate && !this.toDate);
      this.fromDate = null;
      this.toDate = null;
    }

    if (emit) {
      this.control.setValue({
        from: AppDateParserFormatter.ngbDateToIsoString(this.fromDate),
        to: AppDateParserFormatter.ngbDateToIsoString(this.toDate)
      });
    }
  }

  get maxDate(): NgbDateStruct | null {
    return this.max ? AppDateParserFormatter.dateToNgbDateStruct(this.max) : null;
  }

  get minDate(): NgbDateStruct | null {
    return this.min ? AppDateParserFormatter.dateToNgbDateStruct(this.min) : null;
  }
}
