<div class="card">
  <div class="card-body">

    <h5 class="card-title mb-3">
      {{'properties.title' | translate}}
      <a
        [routerLink]="userIdFilter ? '/management/users/'+userIdFilter+'/properties/new' : '/management/properties/new'"
        class="btn ms-2">
        <fa-icon icon="circle-plus"></fa-icon>
      </a>
    </h5>

    <app-table [header]="tableHeader"
               [totalCount]="paginatedProperties?.totalCount"
               [totalPages]="paginatedProperties?.totalPages"
               [isLoading]="isLoading"
               [customFilterForm]="customFilterForm"
               [searchFilters]="searchFilters"
               (tableEvent)="getProperties($event)">

      <ng-container customFilters *ngTemplateOutlet="customFilters"></ng-container>

      <tr *ngFor="let property of paginatedProperties?.data">
        <td>{{property.name}}</td>
        <td>{{property.type}}</td>
        <td>{{property.location}}</td>
        <td>{{property.insertionDate | date}}</td>
        <td>
          <span class="d-inline-block p-2 rounded-circle"
               [class.bg-success]="property.hasCadastrals"
               [class.bg-secondary]="!property.hasCadastrals">
            <span class="visually-hidden">{{'properties.cadastral-info' | translate}}</span>
          </span>
        <td>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch"
                   (change)="toggleEnableProperty($event, property)"
                   [disabled]="isLoadingEnabled"
                   [checked]="property.enabled" />
          </div>
        </td>
        <td>
          <a routerLink="/management/properties/{{property.id}}">{{'next.general.details' | translate}}</a>
        </td>
      </tr>

      <ng-container emptyText>{{'properties.empty-table' | translate}}</ng-container>
    </app-table>

    <ng-template #customFilters [formGroup]="customFilterForm">
      <select class="form-select w-25" aria-label="Type filter" formControlName="type">
        <option [value]="null" selected>{{'properties.type' | translate}}</option>
        <option *ngFor="let type of types" [value]="type.name">{{type.name}}</option>
      </select>
    </ng-template>
  </div>
</div>
