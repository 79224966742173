<div class="card">
  <div class="card-body">
    <div class="container-fluid text-center" *ngIf="isLoadingData; else loaded">
      <next-spinner></next-spinner>
    </div>

    <ng-template #loaded>
      <form [formGroup]="formGroup" (ngSubmit)="submit()">

        <h5 class="card-title mb-3">
          {{'properties.cadastral-info' | translate}}
        </h5>

        <div class="row mb-3">
          <div class="col-sm-12 col-md-4">
            <next-input [label]="'properties.cir-code' | translate" formControlName="cir"></next-input>
          </div>
          <div class="col-sm-12 col-md-4">
            <next-input [label]="'properties.sheet' | translate" formControlName="sheet"></next-input>
          </div>
          <div class="col-sm-12 col-md-4">
            <next-input [label]="'properties.parcel' | translate" formControlName="parcel"></next-input>
          </div>
        </div>

        <div class="text-end">
          <button type="submit" class="btn btn-sm btn-secondary" [isLoading]="isLoading"
                  [disabled]="isLoading || formGroup.pristine">
            <fa-icon *ngIf="!isLoading" icon="paper-plane" class="me-1"></fa-icon>
            {{'next.general.save' | translate}}
          </button>
        </div>
      </form>

      <div class="my-3">
        <h6>{{'properties.cadastral-survey' | translate}}</h6>
        <div class="row justify-content-center mb-3">
          <div class="col-sm-12 col-md-8">
            <app-file-uploader [upload$]="upload$" (fileUpload)="onFileUpload($event)"></app-file-uploader>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <a *ngIf="cadastralDocument; else emptyText" class="btn btn-secondary"
             [href]="cadastralDocument.url" download>
            <fa-icon icon="download" class="me-2" size="sm"></fa-icon>
            {{'next.general.download' | translate}}
          </a>
          <ng-template #emptyText>
            <div class="alert alert-danger mb-0" role="alert">
              {{ 'properties.no-cadastral-survey' | translate }}
            </div>
          </ng-template>
        </div>
      </div>

    </ng-template>

  </div>
</div>
