import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  NextChangeUserPasswordPage,
  NextError,
  NextLoadingButtonComponent,
  NextPasswordInputComponent
} from '@next/next-angular-kit';
import { EMPTY, finalize, first, forkJoin, Observable, of, switchMap, throwError } from 'rxjs';
import { ToastService } from '../../../services/toast/toast.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AdminUsersService, UserRoleType } from '@netserv/kalivah-angular-kit';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'app-change-password[userId]',
  templateUrl: './change-password.component.html',
  imports: [CommonModule, ReactiveFormsModule, NextPasswordInputComponent, TranslateModule, NextLoadingButtonComponent, FontAwesomeModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordComponent extends NextChangeUserPasswordPage implements OnInit {

  @Input() userId!: string;

  /**
   * True if admin try change password for other users
   * @protected
   */
  protected isAdminForUsers = false;

  constructor(
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly adminUsersService: AdminUsersService
  ) {
    super();
  }

  ngOnInit() {
    forkJoin([
      this.adminUsersService.currentUser$.pipe(first()),
      this.adminUsersService.userHasRole(UserRoleType.Administrator).pipe(first())
    ]).subscribe(([currentUser, currentIsAdmin]) => {
      this.isAdminForUsers = currentIsAdmin && currentUser?.id !== this.userId;
      if (this.isAdminForUsers) {
        this.formGroup.get('oldPassword')?.removeValidators(Validators.required);
      } else {
        this.formGroup.get('oldPassword')?.addValidators(Validators.required);
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  protected getUserId(): Observable<string> | string {
    return forkJoin([
      this.adminUsersService.currentUser$.pipe(first()),
      this.adminUsersService.userHasRole(UserRoleType.Administrator).pipe(first())
    ]).pipe(
      switchMap(([currentUser, currentIsAdmin]) => {
        if (!currentIsAdmin && currentUser?.id !== this.userId) {
          this.router.navigate(['forbidden']).then();
          return EMPTY;
        }
        return of(this.userId);
      })
    );
  }

  override submit() {
    if (this.formGroup.invalid) {
      return this.onError?.(undefined, true);
    }

    if (!this.isAdminForUsers) {
      return super.submit();
    }

    this.isLoading = true;
    const userId$ = typeof this.getUserId() === 'string' ?
      of(this.getUserId() as string) : this.getUserId() as Observable<string>;

    userId$.pipe(
      switchMap(userId => {
        const formValue = this.formGroup.value;
        return this.adminUsersService.resetPassword(userId, formValue.password);
      }),
      catchError(err => {
        this.onError?.(err.error);
        return throwError(() => err);
      }),
      finalize(() => this.isLoading = false)
    ).subscribe(() => {
      this.onSuccess();
    });
  }

  protected onSuccess(): void {
    this.formGroup.reset();
    this.translateService.get('auth.success-change-password-message').subscribe(label => {
      this.toastService.success(label);
    });
  }

  protected override onError(error?: NextError, isInvalidForm?: boolean) {
    if (isInvalidForm) {
      this.formGroup.markAllAsTouched();
    }
  }
}
