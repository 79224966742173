<div class="card">
  <div class="card-body">

    <h5 class="card-title mb-3">
      {{'properties.image-gallery' | translate}}
    </h5>

    <div class="container-fluid text-center" *ngIf="isLoading; else loaded">
      <next-spinner></next-spinner>
    </div>

    <ng-template #loaded>
      <div class="d-flex gap-3 overflow-auto mb-2 py-2">

        <div class="image-container">
          <label for="image-input">
            <img ngSrc="/assets/images/default-image.png" class="rounded shadow-sm" alt="add-image" height="124"
                 width="124">
            <fa-icon icon="circle-plus" size="lg"></fa-icon>
          </label>

          <input id="image-input" type="file" accept="image/*" [multiple]="true" (change)="addImage($event.target)" />
        </div>

        <div class="image-container hover-container" *ngFor="let image of images" (click)="openDeleteModal(deleteModalBody, image)">
          <img [ngSrc]="image.url" class="rounded shadow-sm" [alt]="propertyId+'-image-'+image.id" height="124"
               width="124">
          <fa-icon icon="trash" class="text-secondary"></fa-icon>

          <ng-template #deleteModalBody>
            <div class="text-center">
              <img [src]="image.url" class="img-fluid" [alt]="propertyId+'-image-'+image.id">
            </div>
          </ng-template>
        </div>

      </div>
    </ng-template>

  </div>
</div>


