<div class="card">
  <div class="card-body">
    <form [formGroup]="formGroup" (ngSubmit)="submit()">

      <h5 class="card-title mb-3">
        {{'properties.property-info' | translate}}
      </h5>

      <div class="row mb-3">
        <div class="col-sm-12 col-md-6">
          <next-input [label]="'properties.name' | translate" formControlName="name"></next-input>
        </div>
        <div class="col-sm-12 col-md-6">
          <app-geo-info-autocomplete [label]="'properties.location' | translate" formControlName="geo"></app-geo-info-autocomplete>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-sm-12 col-md-6">
          <next-input [label]="'properties.owner-name' | translate" formControlName="ownerName"></next-input>
        </div>
        <div class="col-sm-12 col-md-6">
          <next-input [label]="'properties.owner-surname' | translate" formControlName="ownerSurname"></next-input>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-sm-12 col-md-6">
          <next-select [label]="'properties.type' | translate"
                       [options]="optionTypes"
                       formControlName="propertyTypeId">
            <option [value]="null" disabled [selected]="!property?.typeId">
              {{'properties.type' | translate}}
            </option>
          </next-select>
        </div>
        <div *ngIf="isAdminUser" class="col-sm-12 col-md-6">
          <app-user-autocomplete [label]="'properties.property-manager' | translate"
                                 formControlName="user"></app-user-autocomplete>
        </div>
      </div>

      <div class="text-end">
        <button type="submit" class="btn btn-sm btn-secondary" [isLoading]="isLoading"
                [disabled]="isLoading || formGroup.pristine">
          <fa-icon *ngIf="!isLoading" icon="paper-plane" class="me-1"></fa-icon>
          {{'next.general.save' | translate}}
        </button>
      </div>
    </form>
  </div>
</div>
