import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AdminUsersService, GetUserResponse } from '@netserv/kalivah-angular-kit';
import { UserAccount } from '@next/next-angular-kit';

@Component({
  selector: 'app-user-details',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDetailsComponent {

  /**
   * The user info
   */
  @Input() user: GetUserResponse | undefined;

  /**
   * Update firstname dynamically
   */
  @Input() dynamicFirstName: string | undefined;

  /**
   * Update lastname dynamically
   */
  @Input() dynamicLastName: string | undefined;

  get userProfileImage(): string {
    const user = {
      firstName: (this.dynamicFirstName ?? this.user?.firstName) ?? '',
      lastName: (this.dynamicLastName ?? this.user?.lastName) ?? ''
    } as UserAccount;

    return AdminUsersService.getProfileImageUrl(user);
  }
}
