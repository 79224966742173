import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, finalize, from, map, mergeMap, Observable, of, skipWhile, tap, toArray } from 'rxjs';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-file-uploader',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploaderComponent<T = any> {

  /**
   * The accepted file type to upload <br>
   * Possible values: <a href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types">MIME Types</a> separated by comma
   * @example application/pdf,image/png
   * @default *
   */
  @Input() accept = '*';

  /**
   * If upload multiple files
   * @default false
   */
  @Input() multiple = true;

  /**
   * The upload observable
   */
  @Input() upload$?: (files: FileList) => Array<Observable<HttpEvent<T>>>;

  /**
   * Fired when the single file upload is complete
   */
  @Output() fileUpload = new EventEmitter<T>();

  /**
   * Fired when all files have been uploaded
   */
  @Output() uploadComplete = new EventEmitter<Array<T>>();

  protected isDragover = false;

  /**
   * Progress percentage
   * @default undefined
   */
  protected uploadProgress = 0;
  protected totalUpload?: number;
  protected uploadCount = 0;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
  }

  /**
   * Dragover listener
   * @param evt
   */
  @HostListener('dragover', ['$event'])
  public onDragOver(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.isDragover = true;
  }

  /**
   * Drop leave listener
   * @param evt
   */
  @HostListener('drop', ['$event'])
  public onDrop(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();

    this.isDragover = false;
    const files = evt.dataTransfer?.files;
    if (!files?.length) {
      return;
    }
    this.uploadFiles(files);
  }

  /**
   * Dragleave listener
   * @param evt
   */
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.isDragover = false;
  }

  fileBrowserHandler(evt: Event): void {
    const files = (evt.target as HTMLInputElement)?.files;
    if (!files?.length) {
      return;
    }
    this.uploadFiles(files);
  }


  private uploadFiles(fileList: FileList): void {
    if (!this.upload$) {
      return;
    }

    const uploads$ = this.upload$(fileList);
    this.uploadProgress = 0;
    this.uploadCount = 0;
    this.totalUpload = uploads$.length;
    from(uploads$).pipe(
      mergeMap(upload => {
        let progress = 0;
        return upload.pipe(
          tap(event => { // Update the progress request status
            if (event.type === HttpEventType.UploadProgress) {
              const currentProgress = Math.round((event.loaded / (event.total ?? 1) * 100));
              this.uploadProgress += Math.round((currentProgress - progress) / uploads$.length);
              progress = currentProgress;
              this.changeDetectorRef.detectChanges();
            }
          }),
          skipWhile(event => event.type !== HttpEventType.Response), // skip while the request not is complete
          map(event => (event as HttpResponse<T>).body!), // request is complete with body
          tap(newFile => this.fileUpload.next(newFile)),
          finalize(() => {
            this.uploadProgress += Math.round((100 - progress) / uploads$.length);
            this.uploadCount++;
            this.changeDetectorRef.detectChanges();
          }),
          catchError(() => of(null))
        );
      }, 10),
      toArray()
    ).subscribe(files => {
      const filtered = <Array<T>>files.filter(f => !!f);
      this.uploadComplete.next(filtered);
      setTimeout(() => {
        this.uploadProgress = 0;
        this.uploadCount = 0;
        this.totalUpload = undefined;
        this.changeDetectorRef.detectChanges();
      }, 1000);
    });
  }
}
