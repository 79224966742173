import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../interfaces/app-state';

const appFeature = createFeatureSelector<AppState>('app');

export const selectAccountIsComplete = createSelector(
  appFeature,
  (state: AppState) => state.accountIsComplete
);

export const selectGooglePlacesIsLoaded = createSelector(
  appFeature,
  (state: AppState) => state.googlePlacesIsLoaded
);
