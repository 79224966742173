<label *ngIf="label" [for]="id" class="form-label">{{label}}</label>
<div class="input-group" [class.has-validation]="isValid || isInvalid">
  <input #datepicker="ngbDatepicker"
         [id]="id"
         class="form-control"
         [class.is-valid]="isValid"
         [class.is-invalid]="isInvalid"
         [minDate]="minDate!"
         [maxDate]="maxDate!"
         [placeholder]="placeholder"
         (blur)="markAsTouched()"
         [formControl]="control"
         ngbDatepicker />

  <button class="btn btn-outline-primary" (click)="datepicker.toggle()" type="button">
    <fa-icon icon="calendar-week"></fa-icon>
  </button>

  <div *ngIf="isInvalid" class="invalid-feedback">
    <div #customError>
      <ng-content select="[error]"></ng-content>
    </div>
    <ng-container *ngIf="!customError.hasChildNodes()">{{invalidMessage | async}}</ng-container>
  </div>
</div>
