import { ChangeDetectionStrategy, Component, DoCheck, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { AbstractFormComponent } from '@next/next-angular-kit';
import { AppDateParserFormatter } from '../../../utils/bs-datepicker';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-datepicker',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, FormsModule, NgbInputDatepicker, ReactiveFormsModule],
  templateUrl: './datepicker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerComponent extends AbstractFormComponent<string> implements DoCheck {

  /**
   * The input placeholder
   */
  @Input() placeholder = '';

  /**
   * The max date value
   * @default new Date(2090, 11, 31)
   * @example new Date()
   */
  @Input() max?: Date = new Date(2090, 11, 31);

  /**
   * The min date value
   * @default new Date(1900, 1, 1)
   * @example new Date()
   */
  @Input() min: Date | undefined = new Date(1900, 1, 1);

  override writeValue(value: string) {
    const val = AppDateParserFormatter.isoStringToNgbDateStruct(value);
    this.control.setValue(val, { emitEvent: false });
  }

  override registerOnChange(fn: any): void {
    this.control.valueChanges.subscribe((value: NgbDateStruct | null) => {
      let val: string | null = null;
      if (this.control.valid && value && typeof value === 'object') {
        val = AppDateParserFormatter.ngbDateToIsoString(value);
      }
      fn(val);
    });
    this.onChange = fn;
  }

  override ngDoCheck() {
    if (!this._ngControl?.control) {
      return;
    }
    const ngControl = this._ngControl.control;
    if (this.control.touched !== ngControl.touched) {
      if (ngControl.touched) {
        this.control.markAsTouched();
      } else {
        this.control.markAsUntouched();
      }
      this._changeDetectorRef.detectChanges();
    }
    if (this.control.pristine !== ngControl.pristine) {
      if (ngControl.pristine) {
        this.control.markAsPristine();
      } else {
        this.control.markAsDirty();
      }
      this._changeDetectorRef.detectChanges();
    }
  }

  override get invalidMessage(): Observable<string> {
    if (typeof this.control.value === 'string' && this.hasError('required')) {
      return this._translateService.get('next.errors.invalid-field');
    }

    if (this.control.hasError('ngbDate')) {
      const error = this.control.getError('ngbDate');
      if (error.maxDate) {
        return this._translateService.get('next.errors.max-invalid', {
          max: this.max?.toLocaleDateString()
        });
      }
      if (error.minDate) {
        return this._translateService.get('next.errors.min-invalid', {
          min: this.min?.toLocaleDateString()
        });
      }
    }

    return super.invalidMessage;
  }

  get maxDate(): NgbDateStruct | null {
    return this.max ? AppDateParserFormatter.dateToNgbDateStruct(this.max) : null;
  }

  get minDate(): NgbDateStruct | null {
    return this.min ? AppDateParserFormatter.dateToNgbDateStruct(this.min) : null;
  }
}
