import { KalivahInit } from '@netserv/kalivah-angular-kit';

export const environment = {
  production: false,
  defaultLanguage: 'it',
  kalivahInit: {
    /**
     * The API url
     * @example https://next.net-serv.it/
     */
    baseUrl: 'https://kalivahbackend-dev.azurewebsites.net/',

    /**
     * The clientId for make API request
     */
    clientId: 'b0ff58f48f377ef69c5abfea745654372ec32013.backoffice.kalivah.admin',

    /**
     * The clientSecret for make API request
     */
    clientSecret: 'e8f0c84c-cae0-3750-d890-c79666c6a36f',

    /**
     * The version of API
     */
    version: 1
  } as KalivahInit,

  googlePlacesKey: 'AIzaSyCXN4-PQdOiyTIFTv11iVGymIYggumvJVw'
};
