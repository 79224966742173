import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Optional,
  Renderer2,
  Self
} from '@angular/core';
import { AbstractFormComponent, NextSpinnerComponent } from '@next/next-angular-kit';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { CountriesService, Country } from '@netserv/kalivah-angular-kit';
import { finalize } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, NextSpinnerComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountrySelectComponent extends AbstractFormComponent<string | null> implements OnInit {

  isLoading = false;
  protected options: Array<Country> = [];

  constructor(
    protected override readonly _renderer: Renderer2,
    protected override readonly _elementRef: ElementRef,
    @Self() @Optional() protected override readonly _ngControl: NgControl,
    protected override readonly _translateService: TranslateService,
    protected override readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly countryService: CountriesService
  ) {
    super(_renderer, _elementRef, _ngControl, _translateService, _changeDetectorRef);
  }

  override ngOnInit() {
    super.ngOnInit();

    this.isLoading = true;
    this.countryService.countries().pipe(
      finalize(() => {
        this.isLoading = false;
        this._changeDetectorRef.detectChanges();
      })
    ).subscribe(countries => {
      this.options = countries;
    });
  }

  override writeValue(value: string | null) {
    super.writeValue(value?.trim() === '' ? null : value);
  }

}
