import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  NextInputComponent,
  NextLoadingButtonComponent,
  NextSelectComponent,
  NextValidators,
  SelectControlOption
} from '@next/next-angular-kit';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  AdminPropertiesService,
  AdminUsersService,
  CreatePropertyRequest,
  CreatePropertyResponse,
  GetPropertyResponse,
  PropertiesService,
  PropertyStaffListItemResponse,
  UserListItemResponse,
  UserRoleType
} from '@netserv/kalivah-angular-kit';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { finalize, map, Observable, of, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { UserAutocompleteComponent } from '../../users/user-autocomplete/user-autocomplete.component';
import { KalivahFormComponent } from '../../../abstracts/kalivah-form-component';
import { GeoInfoAutocompleteComponent } from '../../core/geo-info-autocomplete/geo-info-autocomplete.component';

@Component({
  selector: 'app-property-info-form',
  standalone: true,
  imports: [
    CommonModule, TranslateModule, NextInputComponent, FontAwesomeModule, NextLoadingButtonComponent,
    ReactiveFormsModule, UserAutocompleteComponent, NextSelectComponent, GeoInfoAutocompleteComponent
  ],
  templateUrl: './property-info-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyInfoFormComponent extends KalivahFormComponent<CreatePropertyResponse | void> implements OnInit, OnChanges {

  /**
   * The property
   */
  @Input() property: GetPropertyResponse | undefined;

  /**
   * Force creation for userId
   */
  @Input() userId: string | undefined;

  protected optionTypes: Array<SelectControlOption> = [];
  protected propertyOwner?: PropertyStaffListItemResponse;

  /**
   * The current user role
   */
  protected isAdminUser?: boolean;

  constructor(
    private readonly router: Router,
    private readonly adminUsersService: AdminUsersService,
    private readonly propertiesService: PropertiesService,
    private readonly adminPropertiesService: AdminPropertiesService
  ) {
    super();
    this.adminUsersService.userHasRole(UserRoleType.Administrator).subscribe(isAdmin => {
      this.isAdminUser = isAdmin;
      this.formGroup.get('user')?.updateValueAndValidity();
    });
  }

  protected initForm(): FormGroup {
    return this.formBuilder.group({
      name: [null, Validators.required],
      geo: [null, Validators.required],
      propertyTypeId: [null, Validators.required],
      user: [null, NextValidators.conditional(Validators.required, () => !!this.isAdminUser)],
      ownerName: [null, Validators.required],
      ownerSurname: [null, Validators.required]
    });
  }

  ngOnInit() {
    this.propertiesService.getTypes().pipe(
      finalize(() => this.changeDetectorRef.detectChanges())
    ).subscribe(types => {
      this.optionTypes = types.map(type => ({ value: type.id, text: type.name }));
    });

    this.populateStaff();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.formGroup.patchValue({
      ...this.property,
      propertyTypeId: this.property?.typeId ?? null
    });
  }

  private populateStaff(): void {
    if (!this.isAdminUser) {
      return;
    }

    let service$: Observable<UserListItemResponse | undefined> | undefined;
    if (this.property) {
      service$ = this.adminPropertiesService.staffList(this.property.id).pipe(
        map(staffs => {
          this.propertyOwner = staffs.length ? staffs[0] : undefined;
          return this.propertyOwner ? {
            id: this.propertyOwner?.userId,
            firstName: this.propertyOwner?.firstName,
            lastName: this.propertyOwner?.lastName,
            registrationDate: this.propertyOwner?.registrationDate
          } as UserListItemResponse : undefined;
        })
      );
    } else if (this.userId) {
      service$ = this.adminUsersService.get(this.userId).pipe(
        map(user => <UserListItemResponse>user)
      );
    }

    service$?.subscribe(owner => {
      const control = this.formGroup.get('user');
      control?.setValue(owner);
      if (this.userId) {
        control?.disable();
      }
    });
  }


  protected submitAction(formValue: any): Observable<CreatePropertyResponse | void> {
    const { geo, user, ...value } = formValue;

    const request: CreatePropertyRequest = { ...value, ...geo };
    const service$: Observable<CreatePropertyResponse | void> = !this.property ?
      this.adminPropertiesService.create(request) :
      this.adminPropertiesService.update(this.property.id, request);

    return service$.pipe(
      switchMap(res => {
        if (this.isAdminUser) {
          if (!this.property && res) {
            // Admin create property
            return this.adminPropertiesService.createStaff(res.propertyId, user.id).pipe(map(() => res));
          }

          if (this.property) {
            // Admin update property
            if (!this.propertyOwner) {
              return this.adminPropertiesService.createStaff(this.property.id, user.id).pipe(map(() => res));
            }
            if (this.propertyOwner.id !== user.id) {
              return this.adminPropertiesService.updateStaff(this.property.id, user.id).pipe(map(() => res));
            }
          }
        } else if (!this.property && res) {
          // Property Manager create property
          return this.adminUsersService.currentUser$.pipe(
            switchMap(currentUser => this.adminPropertiesService.createStaff(res.propertyId, currentUser!.id)),
            map(() => res)
          );
        }
        return of(res);
      })
    );
  }

  protected submitComplete(res: CreatePropertyResponse | void): void {
    if (!res) {
      this.translateService.get('properties.update-success').subscribe(label => {
        this.toastService.success(label);
      });
      return;
    }

    this.translateService.get('properties.creation-success').subscribe(label => {
      this.router.navigate([`/management/properties/`, res.propertyId]).then(() => {
        this.toastService.success(label);
      });
    });
  }
}
