import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  NextError,
  NextLoadingButtonComponent,
  NextPasswordInputComponent,
  NextResetPasswordPage
} from '@next/next-angular-kit';
import { Router, RouterLink } from '@angular/router';
import { ToastService } from '../../../services/toast/toast.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgOptimizedImage } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  imports: [TranslateModule, ReactiveFormsModule, NextPasswordInputComponent, NextLoadingButtonComponent, RouterLink, FontAwesomeModule, NgOptimizedImage],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent extends NextResetPasswordPage {

  constructor(
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  protected override onSuccess(): void {
    this.router.navigate(['login']).then(() => {
      this.translateService.get('auth.success-change-password-message').subscribe(label => {
        this.toastService.standard(label);
      });
    });
  }

  protected override onError(error?: NextError, isInvalidForm?: boolean) {
    if (isInvalidForm) {
      this.formGroup.markAllAsTouched();
    }
  }

}
