import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastService } from '../services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize, Observable } from 'rxjs';

@Component({ template: '' })
export abstract class KalivahFormComponent<T> {
  /**
   * Loading status
   */
  protected isLoading = false;

  /**
   * Main form
   * @protected
   */
  protected formGroup: FormGroup;


  // Injected
  protected readonly formBuilder: FormBuilder;
  protected readonly toastService: ToastService;
  protected readonly translateService: TranslateService;
  protected readonly changeDetectorRef: ChangeDetectorRef;


  protected constructor() {
    this.formBuilder = inject(FormBuilder);
    this.toastService = inject(ToastService);
    this.translateService = inject(TranslateService);
    this.changeDetectorRef = inject(ChangeDetectorRef);

    this.formGroup = this.initForm();
  }

  /**
   * Called for submit form
   * @protected
   */
  public submit(): void {
    if (this.formGroup.invalid) {
      return this.formGroup.markAllAsTouched();
    }

    this.isLoading = true;
    this.submitAction(this.formGroup.value).pipe(
      finalize(() => {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      })
    ).subscribe(res => this.submitComplete(res));
  }

  /**
   * Initialize the form
   * @protected
   */
  protected abstract initForm(): FormGroup;

  /**
   * Function called if form is valid
   * @protected
   */
  protected abstract submitAction(formValue: any): Observable<T>;

  /**
   * Function called if form is valid
   * @protected
   */
  protected abstract submitComplete(res: T): void;
}
