<div class="row justify-content-center mb-3">
  <div class="col-sm-12 col-md-8">
    <app-file-uploader [upload$]="upload$" (fileUpload)="onFileUpload($event)"></app-file-uploader>
    <p class="text-center w-100 mt-2">{{'users.single-file-document-message' | translate}}</p>
  </div>
</div>

<div class="d-flex justify-content-center">
  <a *ngIf="document; else emptyText" class="btn btn-secondary"
     [href]="document.url" download>
    <fa-icon icon="download" class="me-2" size="sm"></fa-icon>
    {{'next.general.download' | translate}}
  </a>
  <ng-template #emptyText>
    <div class="alert alert-danger mb-0" role="alert">
      {{ 'users.no-identification-document' | translate }}
    </div>
  </ng-template>
</div>
