import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  AccommodationService,
  AdminAccommodationsService,
  AdminPropertiesService,
  CreateAccommodationRequest,
  CreateAccommodationResponse,
  GetAccommodationResponse
} from '@netserv/kalivah-angular-kit';
import { Observable } from 'rxjs';
import {
  AccommodationServiceSearchComponent
} from '../accommodation-service-search/accommodation-service-search.component';
import { NextInputComponent, NextLoadingButtonComponent } from '@next/next-angular-kit';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { KalivahFormComponent } from '../../../abstracts/kalivah-form-component';

@Component({
  selector: 'app-accommodation-info-form[propertyId]',
  standalone: true,
  imports: [CommonModule, AccommodationServiceSearchComponent, TranslateModule, ReactiveFormsModule, NextInputComponent, NextLoadingButtonComponent, FontAwesomeModule],
  templateUrl: './accommodation-info-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccommodationInfoFormComponent extends KalivahFormComponent<CreateAccommodationResponse | void> implements OnChanges {

  /**
   * The property id
   */
  @Input() propertyId!: number;

  /**
   * The accommodation
   */
  @Input() accommodation: GetAccommodationResponse | undefined;

  constructor(
    private readonly router: Router,
    private readonly adminPropertiesService: AdminPropertiesService,
    private readonly adminAccommodationsService: AdminAccommodationsService
  ) {
    super();
  }

  protected initForm(): FormGroup {
    return this.formBuilder.group({
      singleBeds: [0, Validators.required],
      queenSizeBeds: [0, Validators.required],
      kingSizeBeds: [0, Validators.required],
      capacity: [1, Validators.required],
      name: [null, Validators.required],
      services: [null, Validators.required]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.accommodation) {
      this.formGroup.patchValue({
        ...this.accommodation,
        ...this.accommodation.capacity
      });
    }
  }

  protected submitAction(formValue: any): Observable<CreateAccommodationResponse | void> {
    const request: CreateAccommodationRequest = {
      ...formValue,
      totalBeds: formValue.kingSizeBeds + formValue.queenSizeBeds + formValue.singleBeds,
      services: (formValue.services as Array<AccommodationService>).map(s => s.id)
    };

    return !this.accommodation ? this.adminPropertiesService.createAccommodation(this.propertyId, request) :
      this.adminAccommodationsService.update(this.accommodation.id, request);
  }

  protected submitComplete(res: CreateAccommodationResponse | void): void {
    if (!res) {
      this.translateService.get('properties.accommodations.update-success').subscribe(label => {
        this.toastService.success(label);
      });
      return;
    }

    this.translateService.get('properties.accommodations.creation-success').subscribe(label => {
      this.router.navigate([`/management/properties/${this.propertyId}/accommodation/`, res.accommodationId]).then(() => {
        this.toastService.success(label);
      });
    });
  }

}
