import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AuthGuard } from '@next/next-angular-kit';
import { UserRoleType } from '@netserv/kalivah-angular-kit';
import { SidebarData } from '../../interfaces/sidebar-data';
import { faChartPie, faHouse, faUser } from '@fortawesome/free-solid-svg-icons';
import { PropertyUpsertComponent } from './property-upsert/property-upsert.component';
import {
  PropertyAccommodationUpsertComponent
} from './property-accommodation-upsert/property-accommodation-upsert.component';
import { UsersComponent } from './users/users.component';
import { PropertiesComponent } from './properties/properties.component';
import { UserUpsertComponent } from './user-upsert/user-upsert.component';
import { AccountCompleteGuard } from '../../guards/account-complete.guard';


export const AdminRoutes: Routes = [
  {
    path: 'dashboard',
    component: AdminDashboardComponent,
    data: {
      sidebar: { text: 'dashboard.title', icon: faChartPie, order: 1 } as SidebarData,
      breadcrumb: 'dashboard.title'
    }
  },
  {
    path: 'users',
    data: {
      sidebar: { text: 'users.title', icon: faUser, order: 2 } as SidebarData,
      breadcrumb: 'users.title'
    },
    children: [
      {
        path: '',
        component: UsersComponent
      },
      {
        path: 'new',
        component: UserUpsertComponent,
        data: {
          breadcrumb: 'users.insert-new'
        }
      },
      {
        path: ':userId',
        children: [
          { path: '', component: UserUpsertComponent },
          {
            path: 'properties/new',
            component: PropertyUpsertComponent,
            data: {
              breadcrumb: 'properties.insert-new'
            }
          }
        ]
      }
    ]
  }
  // {
  //   path: 'settings',
  //   component: AdminDashboardComponent, // TODO: Create component
  //   data: {
  //     sidebar: { text: 'settings.title', icon: faGear, order: 5 } as SidebarData,
  //     breadcrumb: 'settings.title'
  //   }
  // }
];

export const CommonRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'profile',
    component: UserUpsertComponent,
    data: {
      breadcrumb: 'users.title',
      isProfile: true
    }
  },
  {
    path: 'properties',
    data: {
      sidebar: { text: 'properties.title', icon: faHouse, order: 3 } as SidebarData,
      breadcrumb: 'properties.title'
    },
    children: [
      {
        path: '',
        component: PropertiesComponent
      },
      {
        path: 'new',
        component: PropertyUpsertComponent,
        canActivate: [AccountCompleteGuard],
        data: {
          breadcrumb: 'properties.insert-new'
        }
      },
      {
        path: ':propertyId',
        canActivate: [AccountCompleteGuard],
        children: [
          {
            path: '',
            component: PropertyUpsertComponent,
            data: {
              breadcrumb: 'properties.insert-new'
            }
          },
          {
            path: 'accommodation',
            children: [
              {
                path: 'new',
                component: PropertyAccommodationUpsertComponent,
                data: {
                  breadcrumb: 'properties.accommodations.insert-new'
                }
              },
              {
                path: ':accommodationId',
                component: PropertyAccommodationUpsertComponent
              }
            ]
          }
        ]
      }
    ]
  },
  // {
  //   path: 'tickets',
  //   component: AdminDashboardComponent, // TODO: Create component
  //   data: {
  //     sidebar: { text: 'tickets.title', icon: faTicket, order: 4 } as SidebarData,
  //     breadcrumb: 'tickets.title'
  //   }
  // },
  {
    path: '',
    canActivate: [AuthGuard],
    data: {
      roles: [UserRoleType.Administrator]
    },
    children: AdminRoutes
  }
];


@NgModule({
  imports: [
    RouterModule.forChild([{
      path: '',
      loadComponent: () => import('./_layouts/management-layout/management-layout.component').then(m => m.ManagementLayoutComponent),
      children: CommonRoutes
    }])
  ],
  exports: [RouterModule]
})
export class ManagementRoutingModule {
}
