<div class="container-fluid text-center" *ngIf="isLoading">
  <next-spinner></next-spinner>
</div>

<ng-container *ngIf="!isLoading">
  <div class="card">
    <div class="card-body">

      <div class="row">
        <div class="col-lg-3">
          <app-user-details [user]="user"
                            [dynamicFirstName]="dynamicFirstName"
                            [dynamicLastName]="dynamicLastName"></app-user-details>
        </div>

        <div class="col-lg-9">
          <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs">
            <li [ngbNavItem]="1">
              <button ngbNavLink>{{'users.personal-info' | translate}}</button>
              <ng-template ngbNavContent>
                <app-user-info-form [user]="user" (nameChange)="onNameChange($event)"></app-user-info-form>
              </ng-template>
            </li>

            <li *ngIf="!isAdmin" [ngbNavItem]="2">
              <button ngbNavLink>{{'users.identification-document' | translate}}</button>
              <ng-template ngbNavContent>
                <app-user-documents [userId]="user?.id"></app-user-documents>
              </ng-template>
            </li>

            <li [ngbNavItem]="3" [disabled]="!user">
              <button ngbNavLink>{{'auth.change-password' | translate}}</button>
              <ng-template ngbNavContent>
                <app-change-password *ngIf="user" [userId]="user.id"></app-change-password>
              </ng-template>
            </li>
          </ul>

          <div [ngbNavOutlet]="nav" class="mt-4"></div>
        </div>
      </div>

    </div>
  </div>

  <div class="mt-4" *ngIf="user && !isProfilePage && !isAdmin">
    <app-property-table [userIdFilter]="user.id"></app-property-table>
  </div>
</ng-container>
