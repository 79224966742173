import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AccommodationInfoFormComponent
} from '../../../components/accommodations/accommodation-info-form/accommodation-info-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, finalize, first, of, switchMap } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';
import {
  AccommodationPriceTableComponent
} from '../../../components/accommodation-prices/accommodation-price-table/accommodation-price-table.component';
import { AdminAccommodationsService, GetAccommodationResponse } from '@netserv/kalivah-angular-kit';
import { NextSpinnerComponent } from '@next/next-angular-kit';

@Component({
  selector: 'app-property-accommodation-upsert',
  standalone: true,
  imports: [CommonModule, NextSpinnerComponent, AccommodationInfoFormComponent, AccommodationPriceTableComponent],
  templateUrl: './property-accommodation-upsert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyAccommodationUpsertComponent implements OnInit {

  /**
   * Page loading status
   */
  isLoading?: boolean;

  /**
   * The property id
   */
  propertyId?: number;

  /**
   * The accommodation
   */
  accommodation?: GetAccommodationResponse;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly adminAccommodationsService: AdminAccommodationsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly breadcrumbService: BreadcrumbService
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.route.params.pipe(
      first(),
      switchMap(params => {
        const propertyId = !!params['propertyId'] && Number(params['propertyId']);
        if (!propertyId || isNaN(propertyId) || propertyId === 0) {
          this.router.navigate(['not-found']).then();
          return EMPTY;
        }
        this.propertyId = propertyId;

        const id = !!params['accommodationId'] && Number(params['accommodationId']);
        if (id && !isNaN(id) && id !== 0) {
          return this.adminAccommodationsService.get(id);
        }
        return of(undefined);
      }),
      finalize(() => {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      })
    ).subscribe(accommodation => {
      this.accommodation = accommodation;
      if (this.accommodation?.name) {
        this.breadcrumbService.set( // Personalize the breadcrumb for current property
          this.router.url,
          this.accommodation.name
        );
      }
    });
  }

}
