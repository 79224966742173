<div class="dropzone" [class.dragover]="isDragover">
  <input type="file"
         [accept]="accept"
         [multiple]="multiple"
         [disabled]="!!uploadProgress"
         (change)="fileBrowserHandler($event)" />

  <button class="btn mb-2" [class.text-success]="!!uploadProgress">
    <fa-icon [icon]="isDragover ? 'cloud-arrow-down' : 'cloud-arrow-up'" size="3x"
             [animation]="!!uploadProgress ? 'beat-fade' : undefined"></fa-icon>
  </button>

  <div *ngIf="!uploadProgress; else progress">
    <strong *ngIf="!isDragover">{{'file-uploader.choose-a-file' | translate}}</strong>
    {{(isDragover ? 'file-uploader.drop-file-here' : 'file-uploader.or-drag-it-here') | translate}}.
  </div>

  <ng-template #progress>
    <span class="mb-2">{{'general.loading' | translate}} {{uploadCount}}/{{totalUpload}}...</span>
    <div class="progress" role="progressbar" [attr.aria-label]="'general.loading' | translate"
         [attr.aria-valuenow]="uploadProgress" aria-valuemin="0" aria-valuemax="100">
      <div class="progress-bar bg-success progress-bar-striped" [style.width]="uploadProgress + '%'">
        {{uploadProgress}}%
      </div>
    </div>
  </ng-template>
</div>
