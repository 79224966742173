import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink } from '@angular/router';
import { TableComponent, TableEvent, TableHeader } from '../../core/table/table.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  AccommodationListItemResponse,
  AccommodationService,
  AdminPropertiesService,
  QueryPaginatedSortedRequest
} from '@netserv/kalivah-angular-kit';
import { PaginatedResponse } from '@next/next-angular-kit';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-accommodation-table[propertyId]',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, RouterLink, TableComponent, TranslateModule],
  templateUrl: './accommodation-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccommodationTableComponent {

  /**
   * The property id
   */
  @Input() propertyId!: number;

  /**
   * The properties accommodations
   */
  protected paginatedAccommodations?: PaginatedResponse<AccommodationListItemResponse>;

  /**
   * Loading status
   */
  protected isLoading?: boolean;

  protected tableHeader: TableHeader = [
    { title: 'properties.accommodations.name', sortable: { column: 'Name', direction: 'desc' } },
    { title: 'properties.accommodations.services' },
    { title: '' }
  ];

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly adminPropertiesService: AdminPropertiesService
  ) {
  }

  /**
   * Retrieve the property accommodations
   */
  getPropertyAccommodations(tableEvent: TableEvent): void {
    this.isLoading = true;
    const filter: QueryPaginatedSortedRequest = {
      query: tableEvent.filter,
      page: tableEvent.pageNumber,
      pageSize: tableEvent.pageSize,
      sorter: tableEvent.sort?.column,
      descending: tableEvent.sort?.direction === 'desc'
    };

    this.adminPropertiesService.accommodationsList(this.propertyId, filter).pipe(
      finalize(() => {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      })
    ).subscribe(res => {
      this.paginatedAccommodations = res;
    });
  }

  getServiceColumnValue(services: Array<AccommodationService>): string {
    return services.map(s => s.name).join(', ');
  }
}
