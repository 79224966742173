import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  NextCheckboxComponent,
  NextError,
  NextInputComponent,
  NextLoadingButtonComponent,
  NextLoginPage,
  NextLoginSuccess,
  NextPasswordInputComponent
} from '@next/next-angular-kit';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UserRoleType } from '@netserv/kalivah-angular-kit';
import { ToastService } from '../../../services/toast/toast.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgOptimizedImage } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-login',
  templateUrl: './login.component.html',
  imports: [TranslateModule, ReactiveFormsModule, NextInputComponent, NextPasswordInputComponent, NextLoadingButtonComponent, RouterLink, FontAwesomeModule, NgOptimizedImage, NextCheckboxComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent extends NextLoginPage implements OnInit {

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.route.queryParams.subscribe(params => {
      if (params['email-confirmed']) { // TODO: move to homepage
        this.translateService.get('auth.success-email-confirmed').subscribe(label => {
          this.toastService.success(label);
        });
      }
    })
  }

  protected onSuccess(response: NextLoginSuccess): void {
    const user = response.user;
    const path = (user.roles.some(role => role === UserRoleType.Administrator)) ?
      'management/dashboard' : 'management/properties';

    this.router.navigate([path]).then(() => {
      if (!response.isCache) {
        this.translateService.get('general.welcome').subscribe(label => {
          this.toastService.success(label);
        });
      }
    });
  }

  protected override onError(error?: NextError, isInvalidForm?: boolean) {
    if (isInvalidForm) {
      this.formGroup.markAllAsTouched();
    }
  }
}
