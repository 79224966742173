<div class="card">
  <div class="card-body">

    <h5 class="card-title">
      {{'properties.accommodations.prices.price-settings' | translate}}
      <button class="btn ms-2" (click)="openPriceUpsertModal()">
        <fa-icon icon="circle-plus"></fa-icon>
      </button>
    </h5>

    <app-table #table
               [header]="tableHeader"
               [totalCount]="paginatedPrices?.totalCount"
               [totalPages]="paginatedPrices?.totalPages"
               [isLoading]="isLoading"
               [showFilter]="false"
               (tableEvent)="getPrices($event)">

      <tr *ngFor="let price of paginatedPrices?.data">
        <td>{{price.startDay | date: 'dd/MM/yyyy'}}</td>
        <td>€ {{price.feePerStay | number: '1.2-2' }}</td>
        <td>€ {{price.flatFeePerDay | number: '1.2-2' }}</td>
        <td class="text-center">
          <button class="btn btn-outline-primary" (click)="openPriceUpsertModal(price)">
            <fa-icon icon="pen-to-square"></fa-icon>
          </button>
          <button class="btn btn-outline-secondary ms-2" (click)="openDeletePriceModal(price)">
            <fa-icon icon="trash"></fa-icon>
          </button>
        </td>
      </tr>

      <ng-container emptyText>{{'properties.accommodations.prices.empty-table' | translate}}</ng-container>

    </app-table>

  </div>
</div>

