<div class="card">
  <div class="card-body">

    <h5 class="card-title mb-3">
      {{'properties.accommodation-setting' | translate}}
      <a routerLink="/management/properties/{{propertyId}}/accommodation/new" class="btn ms-2">
        <fa-icon icon="circle-plus"></fa-icon>
      </a>
    </h5>

    <app-table [header]="tableHeader"
               [totalCount]="paginatedAccommodations?.totalCount"
               [totalPages]="paginatedAccommodations?.totalPages"
               [isLoading]="isLoading"
               (tableEvent)="getPropertyAccommodations($event)">

      <tr *ngFor="let accommodation of paginatedAccommodations?.data">
        <td>{{accommodation.name}}</td>
        <td>{{getServiceColumnValue(accommodation.services)}}</td>
        <td>
          <a routerLink="/management/properties/{{propertyId}}/accommodation/{{accommodation.id}}">
            {{'next.general.details' | translate}}
          </a>
        </td>
      </tr>

      <ng-container emptyText>{{'properties.accommodations.empty-table' | translate}}</ng-container>
    </app-table>

  </div>
</div>
