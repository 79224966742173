<form [formGroup]="formGroup" (ngSubmit)=submit()>

  <div *ngIf="!isAdminForUsers" class="mb-3 row">
    <div class="col-sm-12 col-md-6">
      <next-password-input [label]="'auth.old-password' | translate"
                           [validatePassword]="false"
                           formControlName="oldPassword">
        <fa-icon icon="eye-slash" hideIcon></fa-icon>
        <fa-icon icon="eye" showIcon></fa-icon>
      </next-password-input>
    </div>
  </div>

  <div class="mb-5 row">
    <div class="col-sm-12 col-md-6">
      <next-password-input [label]="'auth.new-password' | translate"
                           formControlName="password">
        <fa-icon icon="eye-slash" hideIcon></fa-icon>
        <fa-icon icon="eye" showIcon></fa-icon>
      </next-password-input>
    </div>

    <div class="col-sm-12 col-md-6">
      <next-password-input [label]="'auth.confirm-password' | translate"
                           [validatePassword]="false"
                           formControlName="confirmPassword">
        <fa-icon icon="eye-slash" hideIcon></fa-icon>
        <fa-icon icon="eye" showIcon></fa-icon>
      </next-password-input>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <button class="btn btn-sm btn-secondary" type="submit" [isLoading]="isLoading"
            [disabled]="isLoading || formGroup.pristine">
      <fa-icon *ngIf="!isLoading" icon="paper-plane" class="me-1"></fa-icon>
      {{'auth.change-password' | translate}}
    </button>
  </div>
</form>
