import { createAction, props } from '@ngrx/store';

// Define actions
const CHECK_ACCOUNT = '[Kalivah] Check account';
const CHECK_ACCOUNT_COMPLETE = '[Kalivah] Check account complete';

/**
 * Check if current account is complete with all user information
 */
const checkAccount = createAction(CHECK_ACCOUNT);

/**
 * Check if current account is complete with all user information
 */
const checkAccountComplete = createAction(
  CHECK_ACCOUNT_COMPLETE,
  props<{ isComplete: boolean }>()
);

// GOOGLE PLACES API
const LOAD_GOOGLE_PLACES_API = '[Kalivah] Load Google places API';
const LOAD_GOOGLE_PLACES_API_COMPLETE = '[Kalivah] Load Google places API complete';

/**
 * Load the Google Places API
 */
const loadGooglePlacesApi = createAction(LOAD_GOOGLE_PLACES_API);

/**
 * Load the Google Places API is complete
 */
const loadGooglePlacesApiComplete = createAction(
  LOAD_GOOGLE_PLACES_API_COMPLETE,
  props<{ googlePlacesIsLoaded: boolean }>()
);

// Export the actions
export const appActions = {
  checkAccount,
  checkAccountComplete,

  loadGooglePlacesApi,
  loadGooglePlacesApiComplete
};
