import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyValuePair, ListParams, NextSpinnerComponent } from '@next/next-angular-kit';
import { SortableHeaderDirective } from '../../../directives/sortable-header/sortable-header.directive';
import { debounceTime, startWith } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import {
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbPagination
} from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

export type TableHeader = Array<{
  /**
   * Header title (i18n available)
   */
  title: string;
  sortable?: {
    /**
     * Sortable column key
     */
    column: string;

    /**
     * - <b>asc</b>: Current header is sorted by asc
     * - <b>desc</b>: Current header is sorted by desc
     */
    direction?: 'asc' | 'desc'
  }
}>

export interface TableEvent extends ListParams {
  pageNumber: number;
  pageSize: number;
  sort?: { column: string; direction?: 'asc' | 'desc' };
  filterKeys: Array<string>;
  customFilterValue?: any | undefined;
}

@Component({
  standalone: true,
  selector: 'app-table[header][totalCount]',
  exportAs: 'appTable',
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    SortableHeaderDirective,
    NextSpinnerComponent,
    NgbPagination,
    FormsModule,
    FontAwesomeModule,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownItem
  ],
  templateUrl: './table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements AfterViewInit {

  /**
   * Table header
   */
  @Input() header!: TableHeader;

  /**
   * Loading status
   */
  @Input() isLoading: boolean | undefined;

  /**
   * The current table page size (elements count)
   */
  @Input() totalCount: number | undefined;

  /**
   * The table total pages
   */
  @Input() totalPages: number | undefined;

  /**
   * Show/Hide filter search box
   * @default true
   */
  @Input() showFilter = true;

  /**
   * Search filters for specific search
   * - key: will be returned in the TableEvent
   * - value: can be i18n
   */
  @Input() searchFilters: Array<KeyValuePair> | undefined;

  /**
   * FormGroup as custom filters
   */
  @Input() customFilterForm: FormGroup | undefined;

  /**
   * Fired when table event is changed
   */
  @Output() tableEvent: EventEmitter<TableEvent> = new EventEmitter<TableEvent>();

  /**
   * Sortable table headers
   */
  @ViewChildren(SortableHeaderDirective) private sortableHeaders?: QueryList<SortableHeaderDirective>;

  /**
   * Table filter
   */
  protected filter = new FormControl('', { nonNullable: true });

  /**
   * Current filters for table
   */
  protected filterParams: TableEvent = {
    filterKeys: [],
    pageNumber: 0,
    pageSize: 100
  };

  ngAfterViewInit() {
    const currentSort = this.header.find(t => !!t.sortable?.direction);
    if (currentSort) {
      this.filterParams.sort = currentSort.sortable;
    }

    this.filter.valueChanges.pipe(
      startWith(undefined),
      debounceTime(300) // Time span [ms] has passed without another source emission, to delay data filtering. Useful when the user is typing multiple letters
    ).subscribe(filter => {
      if (filter) {
        this.filterParams.filter = filter;
      } else {
        delete this.filterParams.filter;
      }
      this.changePage(); // Go first page
    });

    this.customFilterForm?.valueChanges.pipe(
      debounceTime(300) // Time span [ms] has passed without another source emission, to delay data filtering. Useful when the user is typing multiple letters
    ).subscribe(value => {
      this.filterParams.customFilterValue = value;
      this.changePage(); // Go first page
    });
  }

  /**
   * Reload table with current filters
   */
  public reload(): void {
    this.emitTableEvent();
  }

  /**
   * On sort change
   * @param event
   */
  protected onSort(event: { column: string; direction?: 'asc' | 'desc' }) {
    // resetting other headers
    this.sortableHeaders?.forEach((header) => {
      if (header.sortable !== event.column) {
        header.direction = undefined;
      }
    });

    this.filterParams.sort = event;
    this.changePage(); // Go first page
  }

  protected toggleFilterKey(event: Event, searchFilter: KeyValuePair): void {
    if ((event.target as HTMLInputElement)?.checked) {
      this.filterParams.filterKeys.push(searchFilter.key);
    } else {
      this.filterParams.filterKeys = this.filterParams.filterKeys.filter(v => v !== searchFilter.key);
    }

    if (this.filter.value) {
      this.changePage(); // Go first page
    }
  }

  /**
   * Change table page
   * @param page the page, current is 1 (index 0)
   * @protected
   */
  protected changePage(page = 1): void {
    this.filterParams.pageNumber = page > 0 ? (page - 1) : 0;
    this.emitTableEvent();
  }

  /**
   * Fire the table event
   * @protected
   */
  protected emitTableEvent(): void {
    this.tableEvent.emit(this.filterParams);
  }
}
