import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangePickerComponent } from '../../core/date-range-picker/date-range-picker.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NextInputComponent, NextLoadingButtonComponent, NextValidators } from '@next/next-angular-kit';
import { TranslateModule } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  AccommodationPriceListItemResponse,
  AdminAccommodationsService,
  CreateAccommodationPriceResponse,
  UpdateAccommodationPriceRequest
} from '@netserv/kalivah-angular-kit';
import { DatepickerComponent } from '../../core/datepicker/datepicker.component';
import { Observable } from 'rxjs';
import { KalivahFormComponent } from '../../../abstracts/kalivah-form-component';

@Component({
  selector: 'app-accommodation-price-upsert-modal[accommodationId]',
  standalone: true,
  imports: [CommonModule, DateRangePickerComponent, FontAwesomeModule, NextInputComponent, NextLoadingButtonComponent, TranslateModule, ReactiveFormsModule, DatepickerComponent],
  templateUrl: './accommodation-price-upsert-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccommodationPriceUpsertModalComponent extends KalivahFormComponent<CreateAccommodationPriceResponse | void> implements OnChanges, OnInit {

  /**
   * The accommodation id
   */
  @Input() accommodationId!: number;

  /**
   * The price to update
   */
  @Input() price: AccommodationPriceListItemResponse | undefined;

  constructor(
    protected readonly activeModal: NgbActiveModal,
    private readonly adminAccommodationsService: AdminAccommodationsService
  ) {
    super();
  }

  protected initForm(): FormGroup {
    return this.formBuilder.group({
      rangeDate: [null, NextValidators.conditional(Validators.required, () => !this.price)],
      startDay: [null, NextValidators.conditional(Validators.required, () => !!this.price)],
      feePerStay: [null, Validators.required],
      flatFeePerDay: [null, Validators.required]
    });
  }

  private addFormData(): void {
    this.formGroup.patchValue({
      startDay: this.price?.startDay ?? null,
      feePerStay: this.price?.feePerStay ?? null,
      flatFeePerDay: this.price?.flatFeePerDay ?? null
    });
    this.formGroup.updateValueAndValidity();
  }

  ngOnInit() {
    this.addFormData();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.addFormData();
  }

  protected submitAction(formValue: any): Observable<CreateAccommodationPriceResponse | void> {
    const request: UpdateAccommodationPriceRequest = {
      startDay: this.price ? formValue.startDay : formValue.rangeDate.from,
      flatFeePerDay: formValue.flatFeePerDay,
      feePerStay: formValue.feePerStay,
      isFlat: this.price?.isFlat ?? true,
      percentagePerDay: this.price?.percentagePerDay ?? 0,
      guestCount: this.price?.guestCount ?? 1
    };

    return !this.price ? this.adminAccommodationsService.createPrice(this.accommodationId, {
      ...request,
      endDay: formValue.rangeDate.to
    }) : this.adminAccommodationsService.updatePrice(this.accommodationId, this.price.id, request);
  }

  protected submitComplete(res: CreateAccommodationPriceResponse | void): void {
    this.translateService.get(`properties.accommodations.prices.${res ? 'creation' : 'update'}-success`).subscribe(label => {
      this.toastService.success(label);
    });

    this.activeModal.close();
  }
}
