import { createReducer, on } from '@ngrx/store';
import { AppState } from '../../interfaces/app-state';
import { appActions } from '../actions/app.actions';

export const initialState: AppState = {};

// Make reducer to modify Auth status on each actions
export const appReducer = createReducer(
  initialState,
  on(appActions.checkAccountComplete, (state, { isComplete }) => ({
    ...state,
    accountIsComplete: isComplete
  })),
  on(appActions.loadGooglePlacesApiComplete, (state, { googlePlacesIsLoaded }) => ({
    ...state,
    googlePlacesIsLoaded
  }))
);

