<div class="container-fluid text-center" *ngIf="isLoading; else loaded">
  <next-spinner></next-spinner>
</div>

<ng-template #loaded>

  <app-accommodation-info-form *ngIf="propertyId"
                               [propertyId]="propertyId"
                               [accommodation]="accommodation"></app-accommodation-info-form>

  <div class="mt-3" *ngIf="accommodation">
    <app-accommodation-price-table [accommodationId]="accommodation.id"></app-accommodation-price-table>
  </div>

</ng-template>
